<style>
  .anchor-padding {
    padding: 0.1rem 1.25rem !important;
  }

  @media only screen and (max-width: 600px) {
    .imgres {
      height: 30px;
      width: 50px;
    }

    .imgres1 {
      height: 50px !important;
      width: 50px !important;
    }
  }

  @media only screen and (min-width: 700px) {
    .imgres {
      height: 40px;
      width: 50px;
    }

    .imgres1 {
      height: 60px !important;
      width: 60px !important;
    }
  }
</style>
<nav class="navbar fixed-top">
  <div class="d-flex align-items-center">
    <a (click)="
        menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); (false)
      " href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg>
      {{ ' ' }}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <!-- <div class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)" />
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div> -->
    <app-search-bar></app-search-bar>
  </div>

  <a style="width: 30%; margin-left: 10px" class="d-flex navbar-logo align-items-center"
    [routerLink]="'/app/dashboards/analytics'" *ngIf="companydata">
    <span *ngIf="imgshow">
      <img class="img-responsive" width="150px" height="50px" style="object-fit: contain"
        src="{{ apiURL }}uploads/company/logo/{{ companydata.companyLogo }}" />
    </span>
    <span class="d-none d-xs-block" width="150px" height="50px" *ngIf="!imgshow">
      <h4>{{ companydata.companyName }}</h4>
    </span>
  </a>

  <div style="align-items: flex-end; padding-right: 30px">
    <div class="header-icons d-inline-block align-middle">
      <!--Notification Icon-->

      <app-user-request-box></app-user-request-box>

      <a (click)="onClick()">
        <div class="position-relative d-inline-flex">
          <span class="badge badge-primary rounded-circle position-absolute top-0 end-0 translate-middle p-1"
            style="font-size: 10px; right: 20px; bottom: 12px">
            {{ notificationcount }}
          </span>
          <span class="glyph-icon simple-icon-bell tool_tip" style="padding-right: 30px; font-size: 18px"
            tooltip="Notification"></span>
        </div>

        {{ ' ' }}
      </a>

      <div class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
            (change)="onDarkModeChange($event)" />
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>
    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{ displayName }}</span>
        <!-- <span>
            <img alt="Profile Picture" src="/assets/img/profiles/l-1.jpg" />
          </span> -->

        <span *ngIf="imgshow1">
          <img alt="img-responsive" class="imgres" src="{{ apiURL }}uploads/user/photo/{{ userdata.photo }} " />
        </span>
        <span *ngIf="!imgshow1">
          <img alt="img-responsive" class="imgres" src="/assets/img/profiles/l-2.jpg" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer" routerLink="/app/me">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span *ngIf="imgshow1">
              <img alt="img-responsive" class="imgres1" src="{{ apiURL }}uploads/user/photo/{{ userdata.photo }} " />
            </span>
            <span *ngIf="!imgshow1">
              <img alt="img-responsive" class="imgres1" src="/assets/img/profiles/l-2.jpg" />
            </span>
            <span class="mt-2 text-capitalize font-weight-bold"> Hi, {{userdata.displayName}}</span>
          </div>
        </a>
        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer anchor-padding" routerLink="/app/changepassword">Change Password</a>
        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer anchor-padding" (click)="onSignOut()">Sign out</a>
      </div>
    </div>
  </div>
</nav>