<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
  <ol class="breadcrumb pt-0">
    <li
      class="breadcrumb-item"
      *ngFor="let sub of pathArr; let i = index"
      [hidden]="i >= pathArr.length - 1"
    >
      <a [routerLink]="getUrl(sub)">{{ getLabel(getUrl(sub)) | translate }}</a>
    </li>
    <li
      class="breadcrumb-item active"
      *ngFor="let sub of pathArr; let i = index"
      [hidden]="i < pathArr.length - 1"
      aria-current="page"
    >
      {{ title || (getLabel('/' + path) | translate) }}
    </li>
  </ol>
</nav>
