import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  TOKEN = 'token';

  LOGIN = 'auth/v1/login';
  FORGOTPASS = 'auth/v1/forgot';
  RESETPASS = 'auth/v1/reset';
  GETCOMPANYDATA = 'companymaster/v1/getalldata';
  GETALLCOMPANYBYID = 'companymaster/v1/getCompanyById';
  SYNCATTENDANCE = 'biometric/v1/biometricsync';
  SYNCATTENDANCE1 = 'biometric/v1/wellsunmedicity';
  VALIDATEATTENDANCE = 'biometric/v1/pathocarevalidator/';
  GETHRSALARYFIELSBYID = 'hrsalaryfields/v1/getbyidactive/';
  GETCOMPANYPAYHEAD = 'hrsalaryfields/v1/getcompanypayhead';
  GETINDEX = 'hrsalaryfields/v1/getindex';
  GETHRSALARYFIELSBYID1 = 'hrsalaryfields/v1/getbyid/';
  getCompanyByParentCompany = 'companymaster/v1/getCompanyByParentCompany';
  getuserinfo = 'companycontact/v1/userinfo/';
  GETBRANCH = 'branchmaster/v1/getalldata';
  getAllBranchDataByCompanyId = 'branchmaster/v1/getAllBranchDataByCompanyId';
  GETCOMPANYTYPEDATA = 'companytype/v1/getalldata';
  GETBANKDATA = 'bankmaster/v1/getalldata';
  GETSHIFTDATA = 'shift/v1/getbycompanyid';
  GETDOCUMENTDATA = 'documentlist/v1/getalldata';
  CREATECOMPANYDATA = 'companymaster/v1/add';
  CREATEBRANCH = 'branchmaster/v1/add';
  UPDATEBRANCH = 'branchmaster/v1/updatebyid';
  CREATECOMPANYTYPEDATA = 'companytype/v1/add';
  CREATEBANK = 'bankmaster/v1/add';
  CREATEDOCUMENT = 'documentlist/v1/add';
  CREATESHIFT = 'shift/v1/add';
  CREATEHRFIELDS = 'hrsalaryfields/v1/add';
  UPDATEHRFIELDS = 'hrsalaryfields/v1/updatebyid';
  UPDATECOMPANYTYPEDATA = 'companytype/v1/updatebyid';
  UPDATEBANK = 'bankmaster/v1/updatebyid';
  CHANGEPASSWORD = 'auth/v1/changepassword';
  UPDATESHIFT = 'shift/v1/updatebyid';
  UPDATEDOCUMENT = 'documentlist/v1/updatebyid';
  UPDATEOMPANYDATA = 'companymaster/v1/updatebyid';
  DELETEOMPANYDATA = 'companymaster/v1/deletebyid';
  DELETEASSET = 'assignasset/v1/deletebyid';
  DELETEBRANCH = 'branchmaster/v1/deletebyid';
  DELETEOMPANYTYPEDATA = 'companytype/v1/deletebyid';
  DELETEBANK = 'bankmaster/v1/deletebyid';
  DELETESHIFT = 'shift/v1/deletebyid';
  DELETEDOCUMENT = 'documentlist/v1/deletebyid';
  VIEWCOMPANYDATA = 'companymaster/v1/getbyid/';
  VIEWBRANCH = 'branchmaster/v1/getbyid/';
  VIEWCOMPANYTYPEDATA = 'companytype/v1/getbyid/';
  VIEWBANK = 'bankmaster/v1/getbyid/';
  VIEWSHIFT = 'shift/v1/getbyid/';
  VIEWDOCUMENT = 'documentlist/v1/getbyid/';
  GETCOUNTRYDATA = 'countrymaster/v1/getalldata';
  GETSTATEBYCOUNTRY = 'statemaster/v1/getbycountryid/';
  GETCITYBYSTATE = 'citymaster/v1/getbgetCityBystateIdyid/';
  COMPANYSTATUSCHANGE = 'companymaster/v1/statuschange';
  ASSETSTATUSCHANGE = 'assignasset/v1/statuschanges';
  BRANCHSTATUSCHANGE = 'branchmaster/v1/statuschange';
  COMPANYSTATUSCHANGES = 'companytype/v1/statuschanges';
  BANKSTATUSCHANGES = 'bankmaster/v1/statuschange';
  SHIFTTATUSCHANGES = 'shift/v1/statuschanges';
  DOCUMENTSTATUSCHANGES = 'documentlist/v1/statuschanges';
  GETCOMPANYCONTACTDATA = 'companycontact/v1/getalldata';
  GETATTENDANCEDATA = 'attendanceTransaction/v1/attendancepareport';

  GETCOMPANYCONTACTDATA2 = 'companycontact/v1/getallsuperadmin';
  GETCOMPANYCONTACTDATA1 = 'companycontact/v1/getbycompanyid';
  CHECKSALARYINCREMENT = 'salaryIncrement/v1/checkdata';
  GETCOMPANYCONTACTDATA3 = 'companycontact/v1/getlist';
  GETERP = 'erpAccountMaster/v1/geterpaccount';
  GETASSETCATEGORYDATA1 = 'assetcategory/v1/getactiveassetcategorybycompanyid/';
  GETPENALTYDATA1 = 'penalty/v1/getactivepenaltybycompanyid/';
  GETPENALTYBYID = 'penalty/v1/getbyid/';
  GETCHECKTRACKING = 'usertracking/v1/check';
  GETCHECKUSERLIMIT = 'companycontact/v1/checkuser';
  GETCOMPANYCONTACTALLDATA = 'companycontact/v1/getlist';
  GETUSERADDRESS = 'useraddress/v1/getbyuserid/';
  GETUSERADDRESSCOMP = 'useraddress/v1/getbyuseridselected/';
  GETUSEREXPRIANCE = 'userexperience/v1/getbyuserid/';
  GETUSEREDUCATION = 'usereducation/v1/getbyuserid/';
  GETUSERFAMILY = 'userfamily/v1/getbyuserid/';
  GETUSERDESSIGNATIONSELECTED = 'empdesignation/v1/getbyuseridselecteds/';
  ADDCOMPANYCONTACTDATA = 'companycontact/v1/add';
  UPLOADEXCELCOMPANYCONTACT = 'companycontact/v1/uploadexcel';
  VIEWCOMPANYCONTACTDATA = 'companycontact/v1/getbyid/';
  UPDATECOMPANYCONTACTDATA = 'companycontact/v1/updatebyid';
  DELETEOMPANYCONTACTDATA = 'companycontact/v1/deletebyid';
  COMPANYCONTACTSTATUSCHANGE = 'companycontact/v1/statuschanges';
  GETPRODUCTDATA = 'productmaster/v1/getalldata';
  GETPROFESSIONAL = 'professionaltaxsetup/v1/getalldatapt';
  GETPFL = 'pfsetup/v1/getalldatapt';
  GETESIC = 'esicsetup/v1/getalldatapt';
  CREATEPRODUCTDATA = 'productmaster/v1/add';
  VIEWPRODUCTDATA = 'productmaster/v1/getbyid/';
  UPDATEPRODUCTDATA = 'productmaster/v1/updatebyid';
  DELETEPRODUCTDATA = 'productmaster/v1/deletebyid';
  PRODUCTSTATUSCHANGES = 'productmaster/v1/statuschanges';
  GETSUBSCRIPTIONDATA = 'subscription/v1/getalldata';
  CREATESUBSCRIPTIONDATA = 'subscription/v1/add';
  VIEWSUBSCRIPTIONDATA = 'subscription/v1/getbyid/';
  UPDATECOMPANYSUBSCRIPTION = 'subscription/v1/updatebyid';
  DELETESUBSCRIPTION = 'subscription/v1/deletebyid';
  SUBSCRIPTIONTATUSCHANGES = 'subscription/v1/statuschanges';
  GETCOMPANYREGISTERDATA = 'companyregister/v1/getalldata';
  CREATECOMPANYREGISTERDATA = 'companyregister/v1/add';
  VIEWCOMPANYREGISTERDATA = 'companyregister/v1/getbyid/';
  UPDATECOMPANYREGISTERDATA = 'companyregister/v1/updatebyid';
  DELETECOMPANYREGISTERDATA = 'companyregister/v1/deletebyid';
  COMPANYREGISTERSTATUSCHANGES = 'companyregister/v1/statuschanges';
  GETDEPARTMENTDATA = 'department/v1/getalldata';
  GETDEPOSITCATEGORYDATA = 'depositCategory/v1/getDepositCategorycompanyid';
  GETDEPOSITCATEGORYDATA1 = 'depositCategory/v1/getDepositCategoryByCompanyId/';
  GETVISITORDATA1 = 'visitors/v1/getvisitorsByCompanyId/';
  GETMEETINGPLACEDATA1 = 'meetingPlace/v1/getmeetingPlaceByCompanyId/';
  CREATEDEPARTMENTDATA = 'department/v1/add';
  CREATEDEPOSITCATEGORYDATA = 'depositCategory/v1/add';
  CREATEMEETINGPLACE = 'meetingPlace/v1/add';
  CREATEVISITOR = 'visitors/v1/add';
  CREATEGATEPASS = 'gatePass/v1/add';
  UPDATEGATEPASS = 'gatePass/v1/updatebyid';
  UPDATEVISITOR = 'visitors/v1/updatebyid';
  CREATEDEPOSITDATA = 'deposit/v1/add';
  UPDATEDEPOSITDATA = 'deposit/v1/updatebyid';
  CREATEERPMASTERDATA = 'erpAccountMaster/v1/add';
  UPDATEERPMASTERDATA = 'erpAccountMaster/v1/updatebyid';
  CREATEEXPENSECATEGORYDATA = 'expensecategory/v1/add';
  CREATEASSETCATEGORYDATA = 'assetcategory/v1/add';
  CREATEPENALTYDATA = 'penalty/v1/add';
  CREATEEXPENSEHEADDATA = 'expensehead/v1/add';
  CREATEweekoffpolicy = 'weekoffpolicy/v1/add';
  VIEWDEPARTMENTDATA = 'department/v1/getbyid/';
  VIEWDEPOSITCATEGORYDATA = 'depositCategory/v1/getbyid/';
  VIEWMEETINGPLACEDATA = 'meetingPlace/v1/getbyid/';
  VIEWVISITORDATA = 'visitors/v1/getbyid/';
  VIEWDEPOSITDATA = 'deposit/v1/getbyid/';
  VIEWERPACCOUNTDATA = 'erpAccountMaster/v1/getbyid/';
  VIEWGATEPASSDATA = 'gatePass/v1/getbyid/';
  VIEWEXPENSECATEGORYDATA = 'expensecategory/v1/getbyid/';
  VIEWASSETCATEGORYDATA = 'assetcategory/v1/getbyid/';
  VIEWPENALTYDATA = 'penalty/v1/getbyid/';
  VIEWEXPENSEHEADDATA = 'expensehead/v1/getbyid/';
  VIEWEXPENSEHEADDATABYCATEGORY = 'expensehead/v1/getactiveexpenseheadbycategoryid/';
  DEPARTMENTBYCOMPANYDATA = 'department/v1/getDepartmentcompanyid';
  DEPOSITCATEGORYBYCOMPANYDATA = 'depositCategory/v1/getDepositCategorycompanyid';
  MEETINGPLACEBYCOMPANYDATA = 'meetingPlace/v1/getmeetingPlacecompanyid';
  VISITORSCOMPANYDATA = 'visitors/v1/getvisitorscompanyid';
  DEPOSITBYCOMPANYDATA = 'deposit/v1/getDepositcompanyid';
  ERPACCOUNTBYCOMPANYDATA = 'erpAccountMaster/v1/geterpAccountMasterByCompanyID';
  GATEPASSBYCOMPANYDATA = 'gatePass/v1/getgatePasscompanyid';
  GATEPASSBYCOMPANYDATA1 = 'gatePass/v1/getgatePassuser';
  EXPENSECATEGORYBYCOMPANYDATA = 'expensecategory/v1/getExpenseCategorycompanyid';
  EXPENSECATEGORYBYCOMPANYDATA1 = 'expensecategory/v1/getExpenseCategoryByCompanyId/';
  ASSETCATEGORYBYCOMPANYDATA = 'assetcategory/v1/getbycompanyid';
  PENALTYBYCOMPANYDATA = 'penalty/v1/getbycompanyid/';
  EXPENSEHEADBYCOMPANYDATA = 'expensehead/v1/getExpenseHeadcompanyid';
  DEPARTMENTBYCOMPANYDATA1 = 'department/v1/getDepartmentByCompanyId/';
  DEPARTMENTBYCOMPANYDATA2 = 'department/v1/getactivedepartmentbycompanyid/';
  DESIGNATIONBYCOMPANYDATA1 = 'designation/v1/getDesignationByCompanyId/';
  DESIGNATIONBYCOMPANYDATA = 'designation/v1/getDesignationcompanyid/';
  UPDATEDEPARTMENTDATA = 'department/v1/updatebyid';
  UPDATEDEPOSITCATEGORYDATA = 'depositCategory/v1/updatebyid';
  UPDATEMEETINGPLACEDATA = 'meetingPlace/v1/updatebyid';
  UPDATEEXPENSECATEGORYDATA = 'expensecategory/v1/updatebyid';
  UPDATEASSETCATEGORYDATA = 'assetcategory/v1/updatebyid';
  UPDATEPENALTYDATA = 'penalty/v1/updatebyid';
  UPDATEEXPENSEHEADDATA = 'expensehead/v1/updatebyid';
  DELETEDEPARTMENTDATA = 'department/v1/deletebyid';
  DELETEDEPOSITCATEGORYDATA = 'depositCategory/v1/deletebyid';
  DELETEMEETINGPLACEDATA = 'meetingPlace/v1/deletebyid';
  DELETEVISITORSDATA = 'visitors/v1/deletebyid';
  DELETEDEPOSITDATA = 'deposit/v1/deletebyid';
  DELETEERPACCOUNTDATA = 'erpAccountMaster/v1/deletebyid';
  DELETEGATEPASSDATA = 'gatePass/v1/deletebyid';
  DELETEEXPENSECATEGORYDATA = 'expensecategory/v1/deletebyid';
  DELETEASSETCATEGORYDATA = 'assetcategory/v1/deletebyid';
  DELETEPENALTYDATA = 'penalty/v1/deletebyid';
  DELETEEXPENSEHEADDATA = 'expensehead/v1/deletebyid';
  DEPARTMENTSTATUSCHANGES = 'department/v1/statuschanges';
  DEPOSITCATEGORYSTATUSCHANGES = 'depositCategory/v1/statuschanges';
  MEETINGPLACESTATUSCHANGES = 'meetingPlace/v1/statuschanges';
  VISITORSTATUSCHANGES = 'visitors/v1/statuschanges';
  DEPOSITSTATUSCHANGES = 'deposit/v1/statuschanges';
  ERPACCOUNTSTATUSCHANGES = 'erpAccountMaster/v1/statuschanges';
  GATEPASSSTATUSCHANGES = 'gatePass/v1/statuschanges';
  EXPENSECATEGORYSTATUSCHANGES = 'expensecategory/v1/statuschanges';
  ASSETCATEGORYSTATUSCHANGES = 'assetcategory/v1/statuschanges';
  PENALTYSTATUSCHANGES = 'penalty/v1/statuschanges';
  EXPENSEHEADSTATUSCHANGES = 'expensehead/v1/statuschanges';
  GETDESIGNATIONDATA = 'designation/v1/getalldata';
  CREATEDESIGNATIONDATA = 'designation/v1/add';
  VIEWDESIGNATIONDATA = 'designation/v1/getbyid/';
  UPDATEDESIGNATIONDATA = 'designation/v1/updatebyid';
  DELETEDESIGNATIONDATA = 'designation/v1/deletebyid';
  DESIGNATIONTATUSCHANGES = 'designation/v1/statuschanges';
  PTADD = 'professionaltaxsetup/v1/add';
  PTGET = 'professionaltaxsetup/v1/getbyid/';
  PFGET = 'pfsetup/v1/getbyid/';
  PFADD = 'pfsetup/v1/add';
  ESICGET = 'esicsetup/v1/getbyid/';
  LETTERGET = 'letterhead/v1/getbycompanyid/';
  ESICADD = 'esicsetup/v1/add';
  LETTERADD = 'letterhead/v1/add';
  CREATEUSERADDRESSDATA = 'useraddress/v1/add';
  UPDATEUSERADDRESSDATA = 'useraddress/v1/updatebyid';
  DELETEUSERADDRESSDATA = 'useraddress/v1/deletebyid';
  USERADDRESSDATASTATUSCHANGE = 'useraddress/v1/statuschanges';
  GETUSERDOCUMENT = 'userdocument/v1/getbyuserid/';
  CREATEUSERDOCUMENT = 'userdocument/v1/add';
  UPDATEUSERDOCUMENT = 'userdocument/v1/updatebyid';
  DELETEUSERDocument = 'userdocument/v1/deletebyid';
  DocumentTATUSCHANGE = 'userdocument/v1/statuschanges';
  CREATEUSEREXPERIENCE = 'userexperience/v1/add';
  UPDATEUSEREXPERIENC = 'userexperience/v1/updatebyid';
  DELETEUSEREXPERIENCE = 'userexperience/v1/deletebyid';
  EXPERIENCESTATUSCHANGE = 'userexperience/v1/statuschanges';
  CREATEUSEREDUCATION = 'usereducation/v1/add';
  UPDATEUSEREDUCATION = 'usereducation/v1/updatebyid';
  DELETEUSEREDUCATION = 'usereducation/v1/deletebyid';
  EDUCATIONSTATUSCHANGE = 'usereducation/v1/statuschanges';
  CREATEUSERFAMILY = 'userfamily/v1/add';
  UPDATEUSERFAMILY = 'userfamily/v1/updatebyid';
  DELETEUSERFAMILY = 'userfamily/v1/deletebyid';
  FAMILYSTATUSCHANGE = 'userfamily/v1/statuschanges';
  CREATEGRADE = 'gradestructure/v1/add';
  UPDATEGRADE = 'gradestructure/v1/updatebyid';
  DELETEGRADE = 'gradestructure/v1/deletebyid';
  DELETEHRFIELD = 'hrsalaryfields/v1/deletebyid';
  GRADESTATUSCHANGE = 'gradestructure/v1/statuschanges';
  HRFIELDSSTATUSCHANGE = 'hrsalaryfields/v1/statuschanges';
  VIEWGrade = 'gradestructure/v1/getbyid/';
  GETGRADE = 'gradestructure/v1/getbycompanyid';
  GETHRFIELD = 'hrsalaryfields/v1/getbycompanyid';
  GETUSERSKILL = 'userskills/v1/getbyuserid/';
  CREATEUSERSKILL = 'userskills/v1/add';
  UPDATEUSERSKILL = 'userskills/v1/updatebyid';
  DELETEUSERSKILL = 'userskills/v1/deletebyid';
  SKILLSTATUSCHANGE = 'userskills/v1/statuschanges';
  CREATEREPORTSTO = 'reportto/v1/add';
  VIEWREPORT = 'reportto/v1/getbyid/';
  GETEMPLOYEEDEPARTMENT = 'empdepartment/v1/getbyuserid/';
  GETEXPENSEPRICE = 'expenseprice/v1/getbyheadid/';
  GETEXPENSEPRICEBYHEAD = 'expenseprice/v1/getExpensePriceRuleByHeadid/';
  CREATEEMPLOYEEDEPARTMENT = 'empdepartment/v1/add';
  CREATEEXPENSEPRICE = 'expenseprice/v1/add';
  CREATEEMPLOYEEDEPARTMENT1 = 'empdepartment/v1/addbulk';
  CREATEEMPLOYEEWEEKOFF1 = 'empweekoff/v1/addbulk';
  CREATEEMPLOYEEHOLIDAY1 = 'empholidaypolicy/v1/addbulk';
  CREATEEMPLOYEEDESIGNATION1 = 'empdesignation/v1/addbulk';
  CREATEEMPLOYEEBRANCH1 = 'empbranch/v1/addbulk';
  CREATEEMPLOYEEASSIGN = 'assignasset/v1/add';
  UPDATEEMPLOYEEASSIGN = 'assignasset/v1/updatebyid';
  RETURNEMPLOYEEASSIGN = 'assignasset/v1/return';
  UPDATEEMPLOYEEDEPARTMENT = 'empdepartment/v1/updatebyid';
  UPDATEEXPENSEPRICE = 'expenseprice/v1/updatebyid';
  DELETEEMPLOYEEDEPARTMENT = 'empdepartment/v1/deletebyid';
  DELETEEXPENSEPRICE = 'expenseprice/v1/deletebyid';
  EMPLOYEEDEPARTMENTSTATUSCHANGE = 'empdepartment/v1/statuschanges';
  GETEMPLOYEEDESIGNATION = 'empdesignation/v1/getbyuserid/';
  CREATEEMPLOYEEDESIGNATION = 'empdesignation/v1/add';
  CREATEPENALTY = 'emppenalty/v1/add';
  FILTERUSER = 'companycontact/v1/filteruser';
  UPDATEEMPLOYEEDESIGNATION = 'empdesignation/v1/updatebyid';
  UPDATEEMPLOYEEPENALTY = 'emppenalty/v1/updatebyid';
  DELETEEMPLOYEEDESIGNATION = 'empdesignation/v1/deletebyid';
  DELETEEMPLOYEEPENALTY = 'emppenalty/v1/deletebyid';
  EMPLOYEEDESIGNATIONSTATUSCHANGE = 'empdesignation/v1/statuschanges';
  EMPLOYEEDPENALTYSTATUSCHANGE = 'emppenalty/v1/statuschanges';
  GETWEEKOFBYCOMPANY = 'weekoffpolicy/v1/getbycompanyid';
  VIEWWEEKOFF = 'weekoffpolicy/v1/getbyid/';
  UPDATEWORKPOLICY = 'weekoffpolicy/v1/updatebyid';
  DELETWEEKOFF = 'weekoffpolicy/v1/deletebyid';
  WEEKOFFSTATUSCHANGES = 'weekoffpolicy/v1/statuschanges';
  WEEKOFFBYCOMPANYDATA = 'empweekoff/v1/getbyuserid/';
  HOLDAYBYCOMPANYDATA = 'empholidaypolicy/v1/getbyuserid/';
  CREATEEMPLOYEEWEEKOFF = 'empweekoff/v1/add';
  CREATEEMPLOYEEHOLIDAY = 'empholidaypolicy/v1/add';
  UPDATEEMPLOYEEWEEKOFF = 'empweekoff/v1/updatebyid';
  UPDATEEMPLOYEHOLIDAY = 'empholidaypolicy/v1/updatebyid';
  DELETEEMPLOYEEWEEKOFF = 'empweekoff/v1/deletebyid';
  DELETEEMPLOYEEHOLIDAY = 'empholidaypolicy/v1/deletebyid';
  EMPLOYEEWEEKOFFSTATUSCHANGE = 'empweekoff/v1/statuschanges';
  CREATECUSTOMER = 'customer/v1/add';
  VIEWCUSTOMER = 'customer/v1/getbyid/';
  UPDATECUSTOMER = 'customer/v1/updatebyid';
  getAllCUSTOMERDataByCompanyId = 'customer/v1/getbycompanyid';
  DELETECUSTOMER = 'customer/v1/deletebyid';
  CUSTOMERSTATUSCHANGE = 'customer/v1/statuschanges';
  GETCOMPDOCUMENTDATA = 'compdoctype/v1/getalldata';
  DELETECOMPDOCUMENT = 'compdoctype/v1/deletebyid';
  COMPDOCUMENTSTATUSCHANGES = 'compdoctype/v1/statuschanges';
  VIEWCOMPDOCUMENT = 'compdoctype/v1/getbyid/';
  UPDATECOMPDOCUMENT = 'compdoctype/v1/updatebyid';
  CREATECOMPDOCUMENT = 'compdoctype/v1/add';
  GETUSERCOMPDOCUMENT = 'compdoc/v1/getbyuserid/';
  CREATEUSERCOMPDOCUMENT = 'compdoc/v1/add';
  UPDATEUSERCOMPDOCUMENT = 'compdoc/v1/updatebyid';
  DELETEUSERCOMPDocument = 'compdoc/v1/deletebyid';
  COMPDocumentTATUSCHANGE = 'compdoc/v1/statuschanges';
  CREATEANNOUNCEMENT = 'announcement/v1/add';
  VIEWANNOUNCEMENT = 'announcement/v1/getbyid/';
  UPDATEANNOUNCEMENT = 'announcement/v1/updatebyid';
  GETANNOUNCEMENTBYCOMPID = 'announcement/v1/getbycompanyid';
  DELETEANNOUNCEMENT = 'announcement/v1/deletebyid';
  ANNOUNCEMENTSTATUSCHANGES = 'announcement/v1/statuschanges';
  UPLOADEXCELCUSTOMER = 'customer/v1/uploadexcel';
  CREATEPT = 'professionataxmaster/v1/add';
  VIEWPT = 'professionataxmaster/v1/getbyid/';
  UPDATEPT = 'professionataxmaster/v1/updatebyid';
  getAllPT = 'professionataxmaster/v1/getalldata';
  DELETEPT = 'professionataxmaster/v1/deletebyid';
  PTSTATUSCHANGE = 'professionataxmaster/v1/statuschanges';
  CREATEEMPLOYEETRACKING = 'usertracking/v1/add';
  GETTRACKINGDATA = 'usertracking/v1/getbycompanyid';
  TRACKDISABLE = 'usertracking/v1/disable';
  GETALLDIGITALSIGNATURE = 'empdigitalsign/v1/getbyuserid/';
  GETALLDIGITALS = 'empdigitalsign/v1/getbyuserids/';
  CREATEDIGITALSIGNATURE = 'empdigitalsign/v1/add';
  UPDATEDIGITALSIGNATURE = 'empdigitalsign/v1/updatebyid';
  DIGITALSIGNATURETATUSCHANGE = 'empdigitalsign/v1/statuschanges';
  DELETEDIGITALSIGNATURE = 'empdigitalsign/v1/deletebyid';
  BRANCHBYCOMPANYDATA1 = 'branchmaster/v1/getbycompanyid/';
  GETEMPLOYEEBRANCH = 'empbranch/v1/getbyuserid/';
  CREATEEMPLOYEEBRANCH = 'empbranch/v1/add';
  UPDATEEMPLOYEEBRANCH = 'empbranch/v1/updatebyid';
  DELETEEMPLOYEEBRANCH = 'empbranch/v1/deletebyid';
  EMPLOYEEBRANCHSTATUSCHANGE = 'empbranch/v1/statuschanges';
  CREATEHOLIDAYPOLICY = 'holidayspolicy/v1/add';
  VIEWHOLIDAYPOLICY = 'holidayspolicy/v1/getbyid/';
  UPDATEHOLIDAYPOLICY = 'holidayspolicy/v1/updatebyid';
  GETHOLIDAYBYCOMPANY = 'holidayspolicy/v1/getalldata';
  DELETHOLIDAY = 'holidayspolicy/v1/deletebyid';
  HOLIDAYSTATUSCHANGES = 'holidayspolicy/v1/statuschanges';
  GETALLEMPLOYEEDEPARTMENT = 'empdepartment/v1/getEmployeeDepartment';
  GETALLEMPLOYEEDESIGNATION = 'empdesignation/v1/getEmployeeDesignation';
  DESIGNATIONBYCOMPANYDATA2 = 'designation/v1/getactivedesignationbycompanyid/';
  BRANCHBYCOMPANYDATA2 = 'branchmaster/v1/getactivebranchbycompanyid/';
  GETALLEMPLOYEEBRANCH = 'empbranch/v1/getEmployeeBranch';
  GETALLassignasset = 'assignasset/v1/getalldata';
  GETALLPENALTY = 'emppenalty/v1/getalldata';
  GETERPSYNC = 'userExpense/v1/expensesyncdata';
  WEEKOFFBYCOMPANYDATA2 = 'weekoffpolicy/v1/getactiveweekoffbycompanyid/';
  GETALLEMPLOYEEDWEEKOFPOLICY = 'empweekoff/v1/getEmployeeWeekoff';
  HOLIDAYBYCOMPANYDATA2 = 'holidayspolicy/v1/getactiveholidaypolicybycompanyid/';
  GETALLEMPLOYEEDHOLIDAYPOLICY = 'empholidaypolicy/v1/getEmployeeHoliday';
  CREATEVISITCUSTOMIZE = 'visitformcustomize/v1/add';
  GETVISITCUSTOMIZEBYCOMPANYID = 'visitformcustomize/v1/getbycompanyid';
  GETDATACALENDER = 'attendanceTransaction/v1/getcalenderdata/';
  DASHBOARD1 = 'auth/v1/dashboard1';
  DELETEVISITCUSTOMIZEBYID = 'visitformcustomize/v1/deletebyid';
  VIEWVISITFORMCUSTOMIZEDATA = 'visitformcustomize/v1/getbyid/';
  UPDATEVISITCUSTOMIZE = 'visitformcustomize/v1/updatebyid';
  CREATEVISITPURPOSE = 'visitpurpose/v1/add';
  GETVISITPURPOSEDATA = 'visitpurpose/v1/getalldata';
  UPDATEVISITPURPOSEDATA = 'visitpurpose/v1/updatebyid';
  DELETEVISITPURPOSEDATA = 'visitpurpose/v1/deletebyid';
  VISITPURPOSEDATASTATUSCHANGES = 'visitpurpose/v1/statuschanges';
  VIEWVISITPURPOSEDATA = 'visitpurpose/v1/getbyid/';
  VISITPURPOSEBYCOMPANYDATA = 'visitpurpose/v1/getbycompanyid';
  CREATESIMPLEPRODUCTDATA = 'product/v1/add';
  GETSIMPLEPRODUCTDATA = 'product/v1/getalldata';
  UPDATESIMPLEPRODUCTDATA = 'product/v1/updatebyid';
  DELETESIMPLEPRODUCTDATA = 'product/v1/deletebyid';
  SIMPLEPRODUCTDATASTATUSCHANGES = 'product/v1/statuschanges';
  VIEWSIMPLEPRODUCTDATA = 'product/v1/getbyid/';
  GETSIMPLEPRODUCTDATABYCOMPANY = 'product/v1/getbycompanyid';
  CREATEVISIT = 'visit/v1/add';
  VIEWVISITBYASSIGN = 'visit/v1/getVisitByAssignID/';
  UPDATEVISIT = 'visit/v1/updatebyid';
  CREATEVISITCUSTOMIZEFIELDVALUE = 'visitformcustomizevalue/v1/add';
  UPDATEVISITCUSTOMIZEFIELDVALUE = 'visitformcustomizevalue/v1/updatebyid';
  VIEWVISIT = 'visit/v1/getbyid/';
  VIEWVISITFIELDCUSTOMIZE = 'visitformcustomizevalue/v1/getbyid/';
  GETVISITBYCOMPID = 'visit/v1/getbycompanyid';
  DELETEVISIT = 'visit/v1/deletebyid';
  VISITSTATUSCHANGES = 'visit/v1/statuschanges';
  CREATEOPERATION = 'operation/v1/add';
  VIEWOPERATION = 'operation/v1/getbyid/';
  UPDATEOPERATION = 'operation/v1/updatebyid';
  GETOPERATION = 'operation/v1/getalldata';
  DELETEOPERATION = 'operation/v1/deletebyid';
  OPERATIONSTATUSCHANGES = 'operation/v1/statuschanges';
  CREATEFORM = 'formmaster/v1/add';
  GETPARENTFORM = 'formmaster/v1/getparentformdata';
  GETFORMDATA = 'formmaster/v1/getalldata';
  DELETEFORM = 'formmaster/v1/deletebyid';
  FORMSTATUSCHANGES = 'formmaster/v1/statuschanges';
  VIEWFORM = 'formmaster/v1/getbyid/';
  UPDATEFORM = 'formmaster/v1/updatebyid';
  VIEWFORMDATA = 'formmaster/v1/getforms';
  CREATERIGHTS = 'userrightsmaster/v1/add';
  GETRIGHTSBYUSERID = 'userrightsmaster/v1/getbyuserid/';

  GETALLPAYHEAD = 'payhead/v1/getalldata';
  CREATEPAYHEAD = 'payhead/v1/add';
  UPDATEPAYHEAD = 'payhead/v1/updatebyid';
  DELETEPAYHEAD = 'payhead/v1/deletebyid';
  PAYHEADSTATUSCHANGE = 'payhead/v1/statuschanges';
  GETPAYHEADBYID = 'payhead/v1/getbyid';

  GETALLLEAVEMASTER = 'leaveMaster/v1/getalldata';
  CREATELEAVEMASTER = 'leaveMaster/v1/add';
  UPDATELEAVEMASTER = 'leaveMaster/v1/updatebyid';
  DELETELEAVEMASTER = 'leaveMaster/v1/deletebyid';
  LEAVEMASTERSTATUSCHANGE = 'leaveMaster/v1/statuschanges';
  GETLEAVEMASTERBYID = 'leaveMaster/v1/getbyid';

  CREATEHRMONTHLYTRANS = 'hrleavesmonthlytrans/v1/add';
  //Attendance Cal Balance
  GETATTENDANCEBAL = 'hrleavesmonthlytrans/v1/AttendanceBal';
  UPDATELEAVETRANS = 'hrleavesmonthlytrans/v1/updateTrans';
  GETEMPBASICDETAILS = 'hrleavesmonthlytrans/v1/empBasicData';

  //Salary Cal Balance
  GETSALARYCAL = 'salaryTrans/v1/add';
  GETSALARYREGISTER = 'salaryTrans/v1/ctcmasterreport';
  GETMONTHLYSALARYREGISTER = 'salaryTrans/v1/monthlyhrsalaryregister';
  GETMONTHLYSALARYTRANSACTIONREGISTER = 'salaryTrans/v1/salarysummaryreport';
  SALARYSLIPCAL = 'salaryTrans/v1/genrateSalarywithsalaryslip';

  GETVISITREPORT = 'report/v1/visitReport';
  GETEXPENSEREPORT = 'report/v1/expenseReport';
  GETVISITREPORTSUMMARY = 'report/v1/visitSummaryReport';

  HRATTENDANCEREPORT = 'report/v1/hrattendancereport';
  HRATTENDANCESALARYREPORT = 'report/v1/hrattendancewithsalaryreport';

  GETALLLeaveTYPES = 'hrleavetypes/v1/getalldata';
  CREATELEAVETYPES = 'hrleavetypes/v1/add';
  UPDATELEAVETYPES = 'hrleavetypes/v1/updatebyid';
  DELETELEAVETYPES = 'hrleavetypes/v1/deletebyid';
  LEAVETYPESSTATUSCHANGE = 'hrleavetypes/v1/statuschanges';
  GETLEAVETYPESBYID = 'hrleavetypes/v1/getbyid';
  GETLEAVETYPEBYCOMPANY = 'hrleavetypes/v1/getdatabycompany';

  ADDSALARYSTRUCTURE = 'hrSalaryMaster/v1/add';

  ADDINCREMENT = 'salaryIncrement/v1/add';

  CREATEVISITREPORTMASTER = 'visitReportMaster/v1/add';
  UPDATEVISITREPORTMASTER = 'visitReportMaster/v1/updatebyid';
  VIEWVISITREPORTMASTER = 'visitReportMaster/v1/getbyid/';
  VISITREPORTMASTERBYCOMPANYDATA = 'visitReportMaster/v1/getalldatabycompanyid';
  VISITREPORTMASTERBYCOMPANY = 'visitReportMaster/v1/getbycompany/';
  DELETEVISITREPORTMASTER = 'visitReportMaster/v1/deletebyid';
  VISITREPORTMASTERSTATUSCHANGES = 'visitReportMaster/v1/statuschanges';

  GETEMPJOININGDATA = 'empJoining/v1/getbyuserid';
  CREATEJOININGDATA = 'empJoining/v1/add';
  UPDATEJOININGDATA = 'empJoining/v1/updatebyid';
  AUTHORIAZATIONALLDATA = 'authorizationCriteriaMasterRoutes/v1/getalldata';
  CREATEFORMAUTHORIZATION = 'formAuthorizationDetails/v1/add';
  GETFORMAUTHORIZATION = 'formAuthorizationDetails/v1/getalldata';
  DELETEFORMAUTHORIZATION = 'formAuthorizationDetails/v1/deletebyid';
  FORMAUTHORIZATIONSTATUSCHANGE = 'formAuthorizationDetails/v1/statuschanges';
  FORMAUTHORIZATIONGETBYID = 'formAuthorizationDetails/v1/getbyid/';
  UPDATEFORMAUTHORIZATION = 'formAuthorizationDetails/v1/updatebyid';
  CREATEVISITREPORTCUSTOMIZE = 'visitReportCustomize/v1/add';
  DELETEVISITREPORTCUSTOMIZEBYID = 'visitReportCustomize/v1/deletebyid';
  GETVISITREPORTCUSTOMIZEBYCOMPANYID = 'visitReportCustomize/v1/getbycompanyid';
  VIEWVISITREPORTFORMCUSTOMIZEDATA = 'visitReportCustomize/v1/getbyid/';
  UPDATEVISITREPORTCUSTOMIZE = 'visitReportCustomize/v1/updatebyid';
  CREATEVISITREPORTCUSTOMIZEFIELDVALUE = 'visitReportCustomizevalue/v1/add';
  VIEWVISITREPORTFIELDCUSTOMIZE = 'visitReportCustomizevalue/v1/getbyid/';
  CREATEMAILCONFIGDATA = 'mailconfig/v1/add';
  GETMAILCONFIGDATA = 'mailconfig/v1/getalldata';
  DELETEMAILCONFIGDATA = 'mailconfig/v1/deletebyid';
  MAILCONFIGSTATUSCHANGES = 'mailconfig/v1/statuschanges';
  VIEWMAILCONFIG = 'mailconfig/v1/getbyid/';
  UPDATEMAILCONFIGDATA = 'mailconfig/v1/updatebyid';

  CREATEPREBOOKINGCUSTOMIZE = 'preboardingformcustomize/v1/add';
  UPDATEPREBOOKINGCUSTOMIZE = 'preboardingformcustomize/v1/updatebyid';
  GETPREBOOKINGCUSTOMIZEBYCOMPANYID = 'preboardingformcustomize/v1/getbycompanyid';
  DELETEPREBOOKINGCUSTOMIZEBYID = 'preboardingformcustomize/v1/deletebyid';

  GETPREBOARDINGCUSTOMIZEBYCOMPANYID = 'preboardingformcustomize/v1/getbycompanyid';
  CREATEPREBOARDING = 'preboarding/v1/add';

  CREATEPREBOARDINGCUSTOMIZEFIELDVALUE = 'preboardingformcustomizevalue/v1/add';

  VIEWCUSTOMIZEDATA = 'preboardingformcustomize/v1/getbyid/';

  CREATECALLFOLLOWUP = 'callfollowup/v1/add';
  UPDATECALLFOLLOWUP = 'callfollowup/v1/updatebyid';
  VIEWCALLFOLLOWUP = 'callfollowup/v1/getbyid/';
  getAllCALLFOLLOWUPDataByCompanyId = 'callfollowup/v1/getCallFollowupuserid';
  DELETECALLFOLLOWUP = 'callfollowup/v1/deletebyid';
  CALLFOLLOWUPSTATUSCHANGE = 'callfollowup/v1/statuschange';
  GETAPPVERSION = 'appversion/v1/getalldata';
  UPDATEAPPVERSION = 'appversion/v1/updatebyid';
  PREBOARDINGBYCOMPANYDATA = 'preboarding/v1/getbycompanyid/';
  ADDPREBOARDINGREQUEST = 'preboardingrequest/v1/add';
  UPDATEPREBOARDING = 'preboarding/v1/updatebyid';
  PREBOARDINGBYUSER = 'preboardingrequest/v1/getalldata';
  UPDATEPREBOARDINGREQUEST = 'preboardingrequest/v1/updatebyid';

  //AdvancePayment View
  CREATEADVANCEPAYMENT = 'advancePayment/v1/add';
  UPDATEADVANCEPAYMENT = 'advancePayment/v1/update';
  GETALLADVANCEDATA = 'advancePayment/v1/getalldata';
  GETADVANCEBYCOMPANYID = 'advancePayment/v1/getcompanydata';
  GETADVANCEBYID = 'advancePayment/v1/getbyid';
  DELETEADVANCE = 'advancePayment/v1/delete';
  ADVANCESTATECHANGE = 'advancePayment/v1/statuschanges';

  //OtherPayment View
  CREATEOTHERPAYMENT = 'otherPayment/v1/add';
  UPDATEOTHERPAYMENT = 'otherPayment/v1/update';
  GETALLOTHERDATA = 'otherPayment/v1/getalldata';
  GETOTHERBYCOMPANYID = 'otherPayment/v1/getcompanydata';
  GETPAYMENTBYID = 'otherPayment/v1/getbyid';
  DELETEPAYMENT = 'otherPayment/v1/delete';

  //EmployeeLeave View
  CREATEEMPLEAVE = 'userleave/v1/add';
  CREATEBULKEMPLEAVE = 'userleave/v1/bulk/add';
  UPDATEEMPLEAVE = 'userleave/v1/update';
  GETLEAVEBYUSER = 'userleave/v1/LeaveByUser';
  GETLEAVEBYCOMPANYID = 'userleave/v1/getcompanyid';
  GETLEAVEBYID = 'userleave/v1/getbyid';
  DELETELEAVE = 'userleave/v1/delete';

  //EmpLeaveBal Model
  CREATELEAVEBAL = 'hrLeaveBal/v1/add';
  GETLEAVEBAL = 'hrLeaveBal/v1/user';
  UPDATELEAVEBAL = 'hrLeaveBal/v1/update';

  //LoanMaster View
  CREATELOANMASTER = 'loanmaster/v1/add';
  UPDATELOANMASTER = 'loanmaster/v1/update';
  GETALLLOANMASTER = 'loanmaster/v1/getalldata';
  GETLOANBYCOMPANYID = 'loanmaster/v1/getLoanByCompanyId';
  GETLOANBYID = 'loanmaster/v1/getbyid';
  DELETELOAN = 'loanmaster/v1/delete';
  UPDATELOANTRANSACTION = 'loanmaster/v1/updateLoanTrans';
  ADDLOANADVANCE = 'loanmaster/v1/advanceLoan/create';
  LoanAdvanceBYID = 'loanmaster/v1/loanAdvance';

  VIEWPREBOARDINGCUSTOMIZE = 'preboardingformcustomizevalue/v1/getbyid/';
  getAllCALLFOLLOWUPDataByVISITID = 'callfollowup/v1/getCallFollowupvisitid';
  DASHBOARDDATA = 'companymaster/v1/superadmin_dashboard';
  GETEMPLOYEESHIFT = 'employeeshift/v1/getbyuserid/';
  CREATEEMPLOYEESHIFT = 'employeeshift/v1/add';
  UPDATEEMPLOYEESHIFT = 'employeeshift/v1/updatebyid';
  DELETEEMPLOYEESHIFT = 'employeeshift/v1/deletebyid';
  GETALLEMPLOYEESHIFT = 'employeeshift/v1/getEmployeeShift';
  CREATEEMPLOYEESHIFT1 = 'employeeshift/v1/addbulk';
  SHIFTBYCOMPANYDATA2 = 'shift/v1/getactiveshiftbycompanyid/';

  CREATEATTENDENCEPOLICY = 'attendancepolicy/v1/add';
  VIEWATTENDENCEPOLICY = 'attendancepolicy/v1/getbyid/';
  UPDATEATTENDENCE = 'attendancepolicy/v1/updatebyid';
  GETATTENDENCEDATA = 'attendancepolicy/v1/getalldata';
  DELETEATTENDENCE = 'attendancepolicy/v1/deletebyid';
  ATTENDENCESTATUSCHANGES = 'attendancepolicy/v1/statuschange';

  CREATESALARYPOLICY = 'salarypolicy/v1/add';
  VIEWSALARYPOLICY = 'salarypolicy/v1/getbyid/';
  UPDATESALARY = 'salarypolicy/v1/updatebyid';
  GETSALARYDATA = 'salarypolicy/v1/getalldata';
  DELETESALARY = 'salarypolicy/v1/deletebyid';
  SALARYSTATUSCHANGES = 'salarypolicy/v1/statuschange';

  GETEMPLOYEEATTENDANCE = 'employeeattendancepolicy/v1/getbyuserid/';
  CREATEEMPLOYEEATTENDANCE = 'employeeattendancepolicy/v1/add';
  UPDATEEMPLOYEEATTENDANCE = 'employeeattendancepolicy/v1/updatebyid';
  DELETEEMPLOYEEATTENDANCE = 'employeeattendancepolicy/v1/deletebyid';

  GETEMPLOYEESALARY = 'employeesalarypolicy/v1/getbyuserid/';
  CREATEEMPLOYEESALARY = 'employeesalarypolicy/v1/add';
  UPDATEEMPLOYEESALARY = 'employeesalarypolicy/v1/updatebyid';
  DELETEEMPLOYEESALARY = 'employeesalarypolicy/v1/deletebyid';

  ATTENDANCEBYCOMPANYDATA2 = 'attendancepolicy/v1/getactiveattendancepolicybycompanyid/';
  SALARYBYCOMPANYDATA2 = 'salarypolicy/v1/getactivesalarypolicybycompanyid/';
  CREATEALLPOLICY = 'employeeattendancepolicy/v1/postAddAllPolicyBULKATTENDANCE';

  AUTHREQUESTDATABYUSER = 'authorizationRequest/v1/getbyuserid';
  AUTHREQUESTACCEPTREJECT = 'authorizationRequest/v1/authorizationacceptreject';
  CREATEAUTHMASTER = 'authorizationmaster/v1/add';
  VIEWAUTHMASTER = 'authorizationmaster/v1/getbyid/';
  UPDATEAUTHMASTER = 'authorizationmaster/v1/updatebyid';
  GETAUTHMASTER = 'authorizationmaster/v1/getalldata';
  DELETEAUTHMASTER = 'authorizationmaster/v1/deletebyid';
  STATUSAUTHMASTER = 'authorizationmaster/v1/statuschange';

  CREATEAUTHORIZATION = 'AuthorizationDetails/v1/add';
  GETAUTHORIZATION = 'AuthorizationDetails/v1/getalldata';
  DELETEAUTHORIZATION = 'AuthorizationDetails/v1/deletebyid';
  AUTHORIZATIONSTATUSCHANGE = 'AuthorizationDetails/v1/statuschanges';
  AUTHORIZATIONGETBYID = 'AuthorizationDetails/v1/getbyid/';
  UPDATEAUTHORIZATION = 'AuthorizationDetails/v1/updatebyid';

  GETPREBOARDINGREQUESTBYPREBOARDINGID =
    'preboardingrequest/v1/getPreboardingRequestByPreboardingId/';

  AUTHREQUESTDATABYREFERANCE = 'authorizationRequest/v1/getAuthorizationRequestByReferanceId/';
  CREATECOMPANYREPORT = 'companywiseReport/v1/add';
  VIEWCOMPANYREPORT = 'companywiseReport/v1/getbyid/';
  UPDATECOMPANYREPORT = 'companywiseReport/v1/updatebyid';
  GETCOMPANYREPORTDATA = 'companywiseReport/v1/getalldata';
  DELETECOMPANYREPORT = 'companywiseReport/v1/deletebyid';
  COMPANYREPORTSTATUSCHANGES = 'companywiseReport/v1/statuschange';
  GETCOMPANYREPORTDATABYCOMPANYID = 'companywiseReport/v1/getCompanywiseReportByCompanyId/';
  GETCOMPANYREPORTDATA1 = 'companywiseReport/v1/getdataofreport';

  CREATETOUR = 'toursMaster/v1/insert_data';
  VIEWTOURDATA = 'toursMaster/v1/getbyid_data/';
  UPDATETOUR = 'toursMaster/v1/update_data';
  GETTOURDATA = 'toursMaster/v1/getbyuserid';
  DELETETOURDATA = 'toursMaster/v1/delete_data/';
  TOURSTATUSCHANGES = 'toursMaster/v1/status_change';

  CREATEOVERTIMEPOLICY = 'overTimePolicy/v1/insert_data';
  VIEWOVERTIMEPOLICY = 'overTimePolicy/v1/getbyid_data/';
  UPDATEOVERTIMEPOLICY = 'overTimePolicy/v1/update_data';
  GETOVERTIMEPOLICYDATA = 'overTimePolicy/v1/view_data';
  DELETEOVERTIMEPOLICYDATA = 'overTimePolicy/v1/delete_data/';
  OVERTIMEPOLICYSTATUSCHANGES = 'overTimePolicy/v1/status_change';

  LEAVEAUTHREQUESTDATABYUSER = 'leaveauthorizationRequest/v1/getbyuserid';
  LEAVEAUTHREQUESTDATABYREFERANCE =
    'leaveauthorizationRequest/v1/getAuthorizationRequestByReferanceId/';

  ATTENDANCEREPORTDATA = 'attendanceTransaction/v1/attendancereport/';
  CREATEBULKATTENDANCEPOLICY = 'employeeattendancepolicy/v1/postAddAttendanceAllPolicyBULK';

  GETOVERTIMEDATA = 'userOverTimePolicyAssign/v1/getbyuserid/';
  CREATEEMPLOYEEOVERTIME = 'userOverTimePolicyAssign/v1/insert_data';
  UPDATEEMPLOYEEOVERTIME = 'userOverTimePolicyAssign/v1/update_data';
  DELETEEMPLOYEEOVERTIME = 'userOverTimePolicyAssign/v1/delete_data';

  CREATEEXPENSE = 'userExpense/v1/add';
  REAPPLYEXPENSE = 'userExpense/v1/reapply';
  GETEXPENSEBYUSERID = 'userExpense/v1/getbyuserid';
  GETREMOVEUNIQUEID = 'companycontact/v1/removeuniqueid';
  GETBYIDEXPENSE = 'userExpense/v1/getbyid/';
  UPDATEEXPENSE = 'userExpense/v1/updatebyid';
  UPDATEEXPENSEERP = 'userExpense/v1/syncexpense';
  AUTHREQUESTDATABYUSEREXPENSE = 'authorizationRequest/v1/expenseauthorizationrequestbyuserid1';
  AUTHDATABYUSEREXPENSE = 'authorizationRequest/v1/getauthrequestdata/';
  DELETEEXPENSE = 'userExpense/v1/delete';
  GETEXPENSEBYUSERID1 = 'userExpense/v1/userExpenseNew';
  AUTHPERSONS = 'userExpense/v1/userExpenseAuth/';
  AUTHREQUESTDATABYREFERANCEEXPENSE =
    'authorizationRequest/v1/getAuthorizationRequestByReferanceIdExpense/';
  AUTHREQUESTACCEPTREJECTEXPENSE = 'authorizationRequest/v1/authorizationacceptrejectexpense';
  AUTHREQUESTDATABYUSEROVERTIME =
    'authorizationRequest/v1/viewauthorizationrequestbyuseridforovertime';
  AUTHREQUESTDATABYREFERANCEOVERTIME =
    'authorizationRequest/v1/getAuthorizationRequestByReferanceIdOvertime/';
  AUTHREQUESTACCEPTREJECTOVERTIME = 'authorizationRequest/v1/authorizationacceptrejectovertime';
  AUTHPERSONS1 = 'userExpense/v1/userExpenseAuthStatus/';
  GETOVERTIEBYUSERID = 'attendanceTransaction/v1/getovertimeuserwise/';
  GETOVERTIMECAL = 'overTime/v1/getdataovertime';
  GETOVERTIMEAMOUNTCAL = 'overTime/v1/getratiowiseovertime';
  EDITOVERTIMEAMOUNTCAL = 'overTime/v1/editovertimecal';
  DELETEOVERTIME = 'overTime/v1/deleteovertimecal';

  LEAVEDETAILS = 'leaveauthorizationRequest/v1/leavedetails/';
  AUTHREQUESTACCEPTREJECTLEAVE = 'leaveauthorizationRequest/v1/authorizationacceptreject';
  GETBRANCHCOMPANY = 'attendanceTransaction/v1/getbranchcompany';
  GETBRANCHWISECOMPANY = 'attendanceTransaction/v1/branchwiseattendancereport';
  GETALLBRANCHWISECOMPANY = 'attendanceTransaction/v1/getallbranchwiseattendancereport';

  DELETESALARYTRAN = 'salaryTrans/v1/deletesalary';
  SALARYSLIPUSERWISE = 'salaryTrans/v1/salaryslipuserwise';
  SALARYDATAUSER = 'salaryTrans/v1/getUserWiseSalarySlip';

  DELETEATTENDANCETRAN = 'hrleavesmonthlytrans/v1/deleteattendance';

  ADDFORM16 = 'form16/v1/add';
  GETFORM16 = 'form16/v1/getAll';
  DELETEFORM16 = 'form16/v1/delete';
  UPDATEFORM16 = 'form16/v1/update';
  GETPARENTFORM16 = 'form16/v1/getAllParent';
  GETCHILDFORM16 = 'form16/v1/getAllChildData';
  FORM16STATUSCHANGES = 'form16/v1/statuschange';
  GETFORM16BYID = 'form16/v1/getbyid/';

  ADDEMPLOYEEINVESTMENT = 'investmentdetails/v1/add';
  GETALLEMPLOYEEINVESTMENT = 'investmentdetails/v1/getAll';
  DELETEEMPLOYEEINVESTMENT = 'investmentdetails/v1/delete';
  UPDATEEMPLOYEEINVESTMENT = 'investmentdetails/v1/update';
  GETBYIDEMPLOYEEINVESTMENT = 'investmentdetails/v1/getById/';
  EMPLOYEEINVESTMENTSTATUSCHANGE = 'investmentdetails/v1/statusChange';
  GETUSERTRACKINGDATA = 'Tracking/v1/getByUserId';
  GETUSERTRACKINGDATA1 = 'Tracking/v1/getUserTrackingInfoById';

  REPORTTO = 'reportTo/v1/reportsto/';
  REPORTTO1 = 'reportTo/v1/reportstostructure/';
  REPORTTO2 = 'reportTo/v1/reportstowithoutchild/';
  REPORTTO3 = 'reportTo/v1/reportstowithoutchildvisit/';
  LAST5ATTENDANCE = 'LastFiveAttendance/v1/getlastfiveattendance';
  PFREPORT = 'report/v1/pfreport';
  ESICCHALLAN = 'report/v1/esicchallan';

  GETALLTAXCHALLAN = 'taxchallanmaster/v1/getAll';
  GETBYIDTAXCHALLAN = 'taxchallanmaster/v1/getById/';
  GETBYIDTAXCHALLAN1 = 'taxchallanmaster/v1/getAllbyID';
  ADDTAXCHALLAN = 'taxchallanmaster/v1/add';
  DELETETAXCHALLAN = 'taxchallanmaster/v1/delete';
  UPDATETAXCHALLAN = 'taxchallanmaster/v1/update';
  TAXCHALLANSTATUSCHANGE = 'taxchallanmaster/v1/poststatus';

  ADDQUATERTAXCHALLAN = 'quartertaxchallan/v1/add';
  GETALLQUATERTAXCHALLAN = 'quartertaxchallan/v1/getAll';
  DELETEQUATERTAXCHALLAN = 'quartertaxchallan/v1/delete';
  UPDATEQUATERTAXCHALLAN = 'quartertaxchallan/v1/update';
  GETBYIDQUATERTAXCHALLAN = 'quartertaxchallan/v1/getById/';
  QUATERTAXCHALLANSTATUSCHANGE = 'quartertaxchallan/v1/poststatus';

  ADDTDSSLAB = 'tdsslabmaster/v1/add';
  GETALLTDSSLAB = 'tdsslabmaster/v1/getAll';
  DELETETDSSLAB = 'tdsslabmaster/v1/delete';
  UPDATETDSSLAB = 'tdsslabmaster/v1/update';
  GETBYIDTDSSLAB = 'tdsslabmaster/v1/getById/';
  TDSSLABSTATUSCHANGE = 'tdsslabmaster/v1/poststatus';

  ADDNDACATEGORY = 'Ndacategory/v1/add';
  GETALLNDACATEGORY = 'Ndacategory/v1/getbycompanyid';
  DELETENDACATEGORY = 'Ndacategory/v1/delete/';
  UPDATENDACATEGORY = 'Ndacategory/v1/update';
  GETBYIDNDACATEGORY = 'Ndacategory/v1/getbyid/';
  NDABYCOMPANYID = 'Ndacategory/v1/bycompanyid';

  ADDEMPLOYEENDA = 'EmployeeNda/v1/add';
  GETALLEMPLOYEENDA = 'EmployeeNda/v1/bycompanyid';
  GETBYIDEMPLOYEENDA = 'EmployeeNda/v1/getbyid/';
  UPDATEEMPLOYEENDA = 'EmployeeNda/v1/update';
  DELETEEMPLOYEENDA = 'EmployeeNda/v1/delete/';

  NDAREPORT = 'NdaReport/v1/getdata';
  DEPOSITREPORT = 'NdaReport/v1/getdatadeposit';
  ASSETREPORT = 'AssetReport/v1/getdata';
  ASSETCATBYCOMPANY = 'assetcategory/v1/getbycompanyid';
  PENALTYREPORT = 'PenaltyReport/v1/getdata';
  ADVANCEREPORT = 'AdvanceReport/v1/getdata';

  CREATEASSETMASTER = 'assetMaster/v1/add';
  GETASSETMASTERDATA = 'assetMaster/v1/getalldata';
  UPDATEASSETMASTERDATA = 'assetMaster/v1/updatebyid';
  DELETEASSETMASTERDATA = 'assetMaster/v1/deletebyid';
  VISITASSETMASTERTATUSCHANGES = 'assetMaster/v1/statuschanges';
  VIEWASSETMASTERDATA = 'assetMaster/v1/getbyid/';
  GETASSETMASTERDATABYCOMPANY = 'assetMaster/v1/bycompanyid';

  GETEMPLOYEEDATA = 'report/v1/employeedetails';

  GETADULTEMPLOYEES = 'report/v1/adultemployees';

  GETADVANCEBYUSERID = 'advancePayment/v1/getuserdata';

  CREATEUSERDEPOSIT = 'deposit/v1/getDepositByUserId';

  GETUSERPENALTY = 'emppenalty/v1/getbyuserid';

  GETUSERLOAN = 'loanmaster/v1/getLoanByUserId';
  GETATTENDANCE = 'attendancetransaction/v1/attendanceByUserid';

  GETATTENDANCELOGBYTRANSID = 'attendancetransaction/v1/getAttendanceLogsByTrnsactionId/';

  GETEXPENSETRANSDATABYVERSION = 'userExpense/v1/getbyversion';

  //calling view
  commonview = 'common/v1/getview';

  //MenuList
  Menulist = 'formmaster/v1/getmenulist';

  //calling Function
  commonfun = 'function/v1';

  GETBYASSETCATEGORYID = 'assignasset/v1/GetbyAssetCategoryID';
  GETASSIGNASSETBYID = 'assignasset/v1/getbyid/';

  GETGRADEDATA = 'gradestructure/v1/getcompany/';
  GETCUSTOMERBYCOMPANY = 'customer/v1/getalldata';

  GETSALARYINCREMENTDATA = 'salaryIncrement/v1/getdata';
  GETWEEKOFBYCOMPANY1 = 'weekoffpolicy/v1/getalldata';

  CREATEEMPLOYEEMENT = 'Employeement/v1/add';
  GETEMPLOYEEMENT = 'Employeement/v1/getalldata';
  GETEMPLOYEEMENTBYCOMPANY = 'Employeement/v1/getEmployeementcompanyid';
  UPDATEEMPLOYEEMENT = 'Employeement/v1/updatebyid';
  VIEWEMPLOYEEMENT = 'Employeement/v1/getbyid/';
  GETEMPLOYEEMENTBYCOMPANYID = 'Employeement/v1/getEmployeementBycompanyid/';
  DELETEEMPLOYEEMENT = 'Employeement/v1/deletebyid';

  CREATEempEMPLOYEEMENT = 'empEmployeement/v1/add';
  GETempEMPLOYEEMENT = 'empEmployeement/v1/getalldata';
  GETempEMPLOYEEMENTBYID = 'empEmployeement/v1/getbyid/';
  GETempEMPLOYEEMENTBYUSERID = 'empEmployeement/v1/getbyuserid/';
  UPDATEempEMPLOYEEMENTBYUSERID = 'empEmployeement/v1/updatebyid';
  DELETEempEMPLOYEEMENT = 'empEmployeement/v1/deletebyid';

  GETFORM18 = 'report/v1/form18';
  GETFORM14 = 'report/v1/form14';

  UPLOADEXCELATTENDANCE = 'biometric/v1/excelattendance';

  GETVISITBYCOMPANY = 'visit/v1/VisitByCompanyId';
  GETTOURBYCOMPANY = 'toursMaster/v1/gettourdatabycompanyid';

  Track_Report = 'report/v1/tracking_report';

  ADDBIOMETRICINTEGRATION = 'biometricintegration/v1/add';
  GETALLBIOMETRICINTEGRATION = 'biometricintegration/v1/getAll';
  STATUSCHANGEBIOMETRICINTEGRATION = 'biometricintegration/v1/statusChange';
  UPDATEBIOMETRICINTEGRATION = 'biometricintegration/v1/update';
  DELETEBIOMETRICINTEGRATION = 'biometricintegration/v1/delete';
  GETBYIDBIOMETRICINTEGRATION = 'biometricintegration/v1/getbyid/';
  GETTABLEANDDB = 'biometricintegration/v1/getbycomp';
  GETSERIALNOBYTABLE = 'biometricintegration/v1/getbytable';
  REMOVESERIALNO = 'biometricintegration/v1/removeSerialNo';

  GETDBNAME = 'tankhwapatraDatabaseList/v1/tankhwapatraDatabase';
  GETTABLENAME = 'tankhwapatraDatabaseList/v1/tankhwapatraGetTable';
  GETSERIALNO = 'tankhwapatraDatabaseList/v1/tankhwapatragetSerialNo';
  CREATETABLE = 'tankhwapatraDatabaseList/v1/tankhwapatraCreateTable';

  GETATTENDANCETRANS = 'shift/v1/getPaneltyData';
  REMOVEPENALTY = 'shift/v1/removePaneltyData';
  ADDPENALTY = 'shift/v1/addPaneltyData';
  ADDFORM = 'auth/v1/allform/';
  getptvaluebycompanystate = 'gradestructure/v1/getptvaluebycompanystate';
  FIELDEFFECT = 'hrsalaryfieldchild/v1/salaryfieldbyeffected/';
  UPLOADEXCELBRANCH = 'branchmaster/v1/uploadexcel';
  UPLOADEXCELDEPARTMENT = 'department/v1/uploadexcel';
  UPLOADEXCELDESIGNATION = 'designation/v1/uploadexcel';
  GETBYIDALARYSTRUCTURE = 'hrSalaryMaster/v1/getbyid/';
  GETSALARYBYGRADE = 'gradestructure/v1/getctcvalue';
  UPDATEGATEPASS1 = 'gatePass/v1/updatebyid1';
  MyTeamList = 'visit/v1/Team_Visit';
  ADDMANUALATTENDANCE = 'mannualAttendance/v1/addmanualAttendance';
  GETBRANCHCONTACT = 'companycontact/v1/getAllCC';
  GETMANNUALATTENDANCEDATA = 'mannualAttendance/v1/getAlldata';
  INCREMENTREPORT = 'report/v1/increment_report';
  LEAVEBALANCEREPORT = 'report/v1/leavebalance_report';
  FORMER01 = 'report/v1/formEr01';
  UPLOADEXCELCOMPANYCONTACTALL = 'companycontact/v1/uploadexcelAll';

  GETATTENDANCEBALANCE = 'hrleavesmonthlytrans/v1/attendanceCalculation';
  GETATTENDANCEBALANCEBYUSERID = 'hrleavesmonthlytrans/v1/getAttendanceCalculationbyuserid';
  GETATTENDANCEVERIFIED = 'hrleavesmonthlytrans/v1/getVerifiedAllData';
  ATTENDANCEUNVERIFY = 'hrleavesmonthlytrans/v1/dataUnverify';
  ATTENDANCEVERIFYSINGLE_MULTIPLE = 'hrleavesmonthlytrans/v1/verifyAllAttendanceCalcution';
  ATTENDANCEUNVERIFY_MULTIPLE = 'hrleavesmonthlytrans/v1/unVerifyAllAttendanceCalcution';
  GETALLCONTACT = 'companycontact/v1/getAllCC';
  GETALLUSER = 'companycontact/v1/getAllUser';

  GETDATEEWISEATTENDANCEPOLICY = 'datewiseattendancepolicy/v1/getbyid/';
  CREATEDATEEWISEATTENDANCEPOLICY = 'datewiseattendancepolicy/v1/add';
  UPDATEDATEEWISEATTENDANCEPOLICY = 'datewiseattendancepolicy/v1/updatebyid';
  DELETEDATEEWISEATTENDANCEPOLICY = 'datewiseattendancepolicy/v1/deletebyid';
  GETDATEEWISEATTENDANCEPOLICYBYCOMPANY = 'datewiseattendancepolicy/v1/datebycompaanyid';
  VIEWDATEWISEATTENDDACEPOLICYBYUSERID = 'datewiseattendancepolicy/v1/getbyid/';
  GETDATEWISEADDATABYID = 'datewiseattendancepolicy/v1/getid/';
  GETALLDATEWISE = 'datewiseattendancepolicy/v1/getalldatabyid';
  GETALLDATEWISEDATA = 'datewiseattendancepolicy/v1/getbycompanyid';

  GETALLCOFF = 'coffMaster/v1/getallCoff';
  DELETECOFF = 'coffMaster/v1/deleteCoff';
  ADDCOFF = 'coffMaster/v1/addCoff';
  GETCOFFBYCOMPANY = 'coffMaster/v1/getAllCoffbyCompany';
  EXPENSEAUTH_DETAILS = 'userExpense/v1/userExpenseAuthDetails/';

  GETALLPRODUCTBYPARENTCHILD = 'product/v1/getallbyparent/';
  EXPENSETRANDELETE_STORE = 'userExpense/v1/expenseDeleteAdd';
  GETBRANCHCONTACTBYDATE = 'companycontact/v1/getbranchcontactBydate';

  GETACTIVESALARYPOLIY = 'employeesalarypolicy/v1/getActive';

  GETALLEMPATTENDANCE = 'employeeattendancepolicy/v1/getAttendancePolicy';
  GETALLEMPSALARY = 'employeesalarypolicy/V1/getSalaryPolicy';
  BULKADDSALARYPOLICY = 'employeesalarypolicy/v1/addbulk';
  GETCURRENTDEPARTMENT = 'empdepartment/v1/getcurrentdepartment';

  GETCURRENTBRANCH = 'empbranch/v1/getcurrentbranch';

  BIOMETRICVALIDATOR = 'biometric/v1/biometricvalidator';
  ATTENDANCEUNVERIFYALL = 'hrleavesmonthlytrans/v1/unVerifyAllAttendanceCalcution';

  EsicReportList = 'report/v1/ESIC';
  PfReport = 'report/v1/pfreport1';
  GETEMPLOYEEPOLICY = 'companycontact/v1/employeepolicy';
  ADDHRTOOLKIT = 'hrToolKit/v1/add';
  GETHRTOOLKIT = 'hrToolKit/v1/getAll';
  DELETEHRTOOLKIT = 'hrToolKit/v1/deletebyID';
  HRTOOLKITSTATUSCHANGE = 'hrToolKit/v1/statuschange';
  GETFORM_11 = 'report/v1/form_11';
  DASHBOARDEMPSTATUS = 'companycontact/v1/dashboardempstatus/';
  DASHBOARDPUNCHINOUT = 'attendanceTransaction/v1/dashboardpunchinout';
  ADDCOFFMASTER = 'coffMaster/v1/addCoffmaster';
  GETNONAUTHORIZEDUSER = 'AuthorizationDetails/v1/nonauthorizeduser';
  GETATTENDANCEDATA2 = 'attendanceTransaction/v1/attendancepareport2';
  GETEMPLOYEEASSET = 'assignasset/v1/getbyUserID';
  GETESIC1 = 'report/v1/esicreport';
  GETLEAVEAUTORIZEDUSER = 'leaveauthorizationRequest/v1/leaveAuthorizeduser';
  GETMYATTENDANCESUMMARY = 'hrleavesmonthlytrans/v1/attendanceSummarybyuserid';
  GETOVERTIMEAUTHORIZEDUSER = 'authorizationRequest/v1/overtimeAuthoriedUser';

  CREATERESIGNATION = 'resignation/v1/add';
  GETALLRESIGNATIONBYAUTH = 'resignation/v1/getAll';
  REJECTRESIGNATION = 'resignation/v1/rejectResignation';
  ACCEPTRESIGNATION = 'resignation/v1/acceptResignation';

  ADDRESIGNATION = 'resignProcess/v1/addresignProcess';
  ADDRESIGNATION1 = 'resignProcess/v1/addresignTask';
  GETALLRESIGNATION = 'resignProcess/v1/getAllresignProcess';
  GETALLTASK = 'resignProcess/v1/getAllresignProcesswithtask';
  CHANGERESIGNSTATUS = 'resignProcess/v1/statusChanged';
  UPDATERESIGN = 'resignProcess/v1/update';

  GETALLRESIGNTASK = 'resignTaskAssign/v1/getAllresigntaskAssign';
  UPDATERESIGNTASK = 'resignTaskAssign/v1/postUpdateresignTaskAssign';

  GETALLINCENTIVEDATA = 'incentive/v1/getalldatabycompanyid';
  INCENTIVETYPEADD = 'incentive/v1/add';
  INCENTIVETYPEUPDATE = 'incentive/v1/updatebyid';
  INCENTIVESTATUSCHANGES = 'incentive/v1/statuschanges';
  INCENTIVEDELETE = 'incentive/v1/deletebyid';
  GETBYIDINCENTIVE = 'incentive/v1/getbyid/';
  DELETEBYID = 'incentive/v1/destroy';

  ADDEMPLOYEEINCENTIVE = 'employeeincentive/v1/add';
  UPDATEEMPLOYEEINCENTIVE = 'employeeincentive/v1/updatebyid';
  DELETEEMPLOYEEINCENTIVE = 'employeeincentive/v1/statuschanges';
  GETEMPLOOYEEBYINCENTIVEID = 'employeeincentive/v1/getbycompanyid';
  GETBYNAME = 'employeeincentive/v1/getbyname';
  GETALLEMPLOYEEINCENTIVEDATA = 'employeeincentive/v1/getalldata';
  UPDATEFILTER = 'employeeincentive/v1/searchquery';
  GETBYID = 'employeeincentive/v1/getbyid/';

  ADDTASK = 'dailyTask/v1/add';
  GETALLDAILYTASK = 'dailyTask/v1/getAll';
  DELETETASK = 'dailyTask/v1/deletebyID';
  UPDATETASK = 'dailyTask/v1/update';
  GETTASKBYID = 'dailyTask/v1/getbyID/';

  CHANGESALARYBYGRADE = 'gradestructure/v1/getctcvalue1';

  GETGRADEFORDRPDOWN = 'hrsalaryfields/v1/getbycompanyidfordropdown';
  GETGRADESALARYSTRUCTUREBYGRADEID = 'gradesalarystructure/v1/getsalarystructuredataBygradeId';

  AUTHREQUESTACCEPTREJECTEXPENSEALL = 'authorizationRequest/v1/authorizationacceptrejectexpenseall';
  GETCALCULATEDSALARY = 'salaryTrans/v1/getsalary';

  LEAVECANCELLIST = 'leaveauthorizationRequest/v1/leavecancellist';
  LEAVECANCEL = 'leaveauthorizationRequest/v1/leavecancel';
  GETEMPLOYEELIST = 'empJoining/v1/getEMPList';

  REMOVESALARYSTRUCTURE = 'hrSalaryMaster/v1/removeStructrureByid';
  DELETESALARYMASTER = 'hrSalaryMaster/v1/deleteSalaryMaster';

  PREBOARDINGSTATUSCHANGE = 'preboarding/v1/deletebyid';
  PREBOARDINGGETBYID = 'preboarding/v1/getbyid/';
  DELETEALLSALARY = 'salaryTrans/v1/deleteAllsalary';
  GETLEAVEDETAILBYID = 'userleave/v1/LeavedetailById/';
  PROFILEINFO = 'auth/v1/profile/';
  GATEPASSDASHBOARD = 'gatePass//v1/gatepassdashboard';
  GATEDAILYVEHICLEUSAGEREPORT = 'vehicleUsage/v1/getuserdailyvehicleusagereport';
  GETLEAVETYPEBYUSERCOMPANY = 'hrleavetypes/v1/getdatabyusercompany';
  SALARYREGISTERREPORT = 'report/v1/salaryRegister';

  ADDEMPACCIDENT = 'employeeAccident/v1/add';
  GETEMPACCIDENTDATA = 'employeeAccident/v1/getEmpAccidentData';
  GETEMPACCIDENTDATABYID = 'employeeAccident/v1/getEmpAccidentDataById/';
  UPDATEEMPACCIDENTDATA = 'employeeAccident/v1/updatebyid';
  DELETEEMPACCIDENTDATA = 'employeeAccident/v1/delete';

  GETFORM_29 = 'report/v1/form_29';
  GETVISITREPORTBYCUSTMOER = 'report/v1/visitReportByCustomer';

  EXPENSEPAYMENTLIST = 'expensePayment/v1/expensePaymentData';
  EXPENSEPAYMENTADD = 'expensePayment/v1/add';
  EXPENSEPAYMENTDETAILBYTRANID = 'expensePayment/v1/expensePaymentDetailsByTranId/';
  LISTPAIDEXPENSE = 'expensePayment/v1/listPaidExpense';
  DELETEPAIDEXPENSE = 'expensePayment/v1/delete';
  GETPAIDEXPENSEBYID = 'expensePayment/v1/getByexpensePaymentId/';
  UPDATEPAIDEXPENSEBYID = 'expensePayment/v1/updatebyid';

  GETVEHICLEUSAGEBYCOMPANYID = 'vehicleUsage/v1/getVehicleUsageByCompanyId';

  CALCULATEINCREMENT = 'salaryIncrement/v1/calculateincrement';
  CHANGECALCUTEINCREMENT = 'salaryIncrement/v1/changeincrement';

  OVERTIMEREPORT = 'overTime/v1/getovertimereport';
  GETRESIGNATIONBYUSERID = 'resignation/v1/getResignationByUserId/';

  DELETERESIGNATIONBYID = 'resignation/v1/postDeleteResignationById';

  ADDTASKSTAGES = 'Tasks_Stages/v1/add';
  GETALLTASKSTAGES = 'Tasks_Stages/v1/getalldata';
  GETBYIDTASKSTAGES = 'Tasks_Stages/v1/getbyid/';
  UPDATETASKSTAGES = 'Tasks_Stages/v1/updatebyid';
  DELETETASKSTAGE = 'Tasks_Stages/v1/deletebyid';
  STATUSCHANGETASKSTAGE = 'Tasks_Stages/v1/statuschange';
  GETALLSTAGESBYID = 'Tasks_Stages/v1/getTaskStageByIdArray';

  ADDTASK1 = 'user_tasks/v1/add';
  GETTASKBYCOMPANY = 'user_tasks/v1/getTaskbyCompany';
  GETTASKBYUSER = 'user_tasks/v1/getTaskbyUser';
  GETTASKBYID1 = 'user_tasks/v1/getbyID/';
  UPDATETASK1 = 'user_tasks/v1/updateTask';
  DELETETASK1 = 'user_tasks/v1/deleteTask';
  GETNEXTSTAGEBYID = 'user_tasks/v1/getNextStageById/';
  UPDATETASKSTAGE = 'user_tasks/v1/postUpdateTaskStage';
  TASKACCEPTREJECT = 'user_tasks/v1/TaskAcceptReject';
  ADDLEAVEBALANCE = 'hrLeaveBal/v1/addLeaveBalance';

  GETLEAVEBALANCEADDED = 'hrLeaveBal/v1/getLeavesAddedById/';
  GETLEAVEBALANCEUSED = 'userleave/v1/getLeaveByUserId/';
  WAGESSALARYREGISTER = 'report/v1/wagesofsalaryregister';
  GETOVERTIMEUSERWISE = 'overTime/v1/getOvertimeDataUserwise';

  ADDREMARK = 'taskRemark/v1/postAddTaskRemark';
  GETTASKREMARK = 'taskRemark/v1/getTaskById/';
  GETNOTIFICATION = 'announcement/v1/getbyuserId';
  GETNOTIFICATIONCOUNT = 'announcement/v1/countUnreadAnnouncement';
  GETPERMISSION = 'auth/v1/finalcheckpermission';
  UPLOADBIOMETRICEXCEL = 'mannualAttendance/v1/uploadboimetricexcel';

  GETEXPENSECATEGORYDATA = 'expensecategory/v1/getalldata';
  UPLOADEXPENSEEXCEL = 'expensecategory/v1/uploadexcel';
  GETALLASSETDATA = 'assetcategory/v1/getalldata';
  UPLOADTASKSTAGEDATA = 'Tasks_Stages/v1/uploadexcel';
  UPLOADDEPOSIT = 'depositCategory/v1/postUploadExcel';
  UPLOADASSETDATAEXCEL = 'assetcategory/v1/uploadAssetExcel';

  UPLOADNDAEXCELDATA = 'Ndacategory/v1/uploadexcel';
  UPLOADMEETINGPLACE = 'meetingPlace/v1/uploadexcel';
  UPLOADVISITORSEXCEL = 'visitors/v1/uploadexcel';
  UPLOADVISITPURPOSEEXCEL = 'visitpurpose/v1/uploadexcel';
  PAMUSTERROLLREGISTER = 'report/v1/paMusterRoll';
  INOUTATTENDANCEREGISTER = 'report/v1/inoutAttendanceRegister';

  CREATEVISITCUSTOMIZEFIELDVALUEWEB = 'visitformcustomizevalue/v1/webadd';
  UPDATEVISITCUSTOMIZEFIELDVALUEWEB = 'visitformcustomizevalue/v1/webupdatebyid';
  GETASSETCATEGORYBYCOMPID = 'assetMaster/v1/getassetcategorybycompid';

  GETALLEXPENSEHEADDATA = 'expensehead/v1/getalldata';
  UPLOADEXPENSEHEADEXCEL = 'expensehead/v1/uploadexcel';
  GETEXPENSECATEGORIES = 'expensehead/v1/excel1';
  GETFORM_5 = 'report/v1/form_5';
  ADDENDINGVEHICLEUSAGE = 'vehicleUsage/v1/addEndingVehicleUsage';
  OVERTIMEAPPLICABLEREPORT = 'report/v1/overtimeapplicablereport';
  GETALLINDIACITY = 'customer/v1/getAllIndiaCityList';

  getCompanyByParentCompany2 = 'companymaster/v1/getCompanyByParentCompany2';
  GETCOMPANYANALYTICSDATA = 'companymaster/v1/getCompanyAnalyticsData';
  GETCOMPANYCONTACTSUBADMINDATA = 'companycontact/v1/getallsubadmin';

  GETCOMPANYDATASUBADMIN = 'companymaster/v1/getCompanyDataSubAdmin';

  GETDATACALENDERMONTHWISE = 'attendanceTransaction/v1/getcalenderdatamonthwise';

  CREATEMAILTEMPLATETYPEDATA = 'mailTemplateType/v1/add';
  GETMAILTEMPLATETYPEDATA = 'mailTemplateType/v1/getalldata';
  DELETEMAILTYPEDATA = 'mailTemplateType/v1/deletebyid';
  MAILSTATUSCHANGES = 'mailTemplateType/v1/statuschanges';
  VIEWMAILTYPEDATA = 'mailTemplateType/v1/getbyid/';
  UPDATEMAILTYPEDATA = 'mailTemplateType/v1/updatebyid';

  ADDMAILFIELDS = 'mailFields/v1/add';
  GETALLMAILFIELDSDATA = 'mailFields/v1/getalldata';
  DELETEMAILFIELDDATA = 'mailFields/v1/deletebyid';
  MAILFIELDSSTATUSCHANGES = 'mailFields/v1/statuschanges';
  GETALLMAILTEMPLATETYPEBYID = 'mailFields/v1/getMailFieldsByMailType';

  CREATELETTERTEMPLATETYPEDATA = 'letterTemplateType/v1/add';
  GETLETTERTEMPLATETYPEDATA = 'letterTemplateType/v1/getalldata';
  DELETELETTERTYPEDATA = 'letterTemplateType/v1/deletebyid';
  LETTERSTATUSCHANGES = 'letterTemplateType/v1/statuschanges';
  VIEWLETTERTYPEDATA = 'letterTemplateType/v1/getbyid/';
  UPDATELETTERTYPEDATA = 'letterTemplateType/v1/updatebyid';

  GETALLLETTERFIELDSDATA = 'letterFields/v1/getalldata';
  DELETELETTERFIELDDATA = 'letterFields/v1/deletebyid';
  LETTERFIELDSSTATUSCHANGES = 'letterFields/v1/statuschanges';
  GETALLLETTERTEMPLATETYPEBYID = 'letterFields/v1/getLetterFieldsByLetterType';
  ADDLETTERFIELDS = 'letterFields/v1/add';

  NDACATEGORYSTATUSCHANGE = 'Ndacategory/v1/poststatuschange';

  ADDNOTIFICATIONPOLICY = 'notificationPolicy/v1/add';
  GETNOTIFICATIONDATABYCOMPANYID = 'notificationPolicy/v1/getNotificationPolicyDataByCompanyId/';

  ADDMAILTEMPLATE = 'mailTemplateEditor/v1/add';
  GETALLTEMPLATEDATA = 'mailTemplateEditor/v1/getalldata';
  GETTEMPLATEDATABYID = 'mailTemplateEditor/v1/getbyid/';
  DELDATABYID = 'mailTemplateEditor/v1/deletebyid';
  MAILSTATUS = 'mailTemplateEditor/v1/statuschanges';
  EDITTEMPLATE = 'mailTemplateEditor/v1/updatebyid';

  ADDLETTEREDITOR = 'letterTamplateEditor/v1/add';
  GETALLLETTERDATA = 'letterTamplateEditor/v1/getalldata';
  GETLETTERDATABYID = 'letterTamplateEditor/v1/getbyid/';
  EDITLETTERBYID = 'letterTamplateEditor/v1/updatebyid';
  DELETELETTERBYID = 'letterTamplateEditor/v1/deletebyid';
  LETTERTEMPSTATUS = 'letterTamplateEditor/v1/statuschanges';

  HourlySalaryRegister = 'report/v1/hourlySalaryRegister';
  LWFREPORT = 'report/v1/lwf_report';
  ANNIVERSARY = 'auth/v1/work-anniversary/';

  CONSOLIDATEDREPORT = 'attendanceTransaction/v1/consolidatedreport';

  GOEARLYLATEBYREPORT = 'attendanceTransaction/v1/goearlylatebyreport';

  RESETPASSWORD = 'auth/v1/resetpassword';

  GETALLSKILLSET = 'skillsets/v1/getallskillset';
  SKILLSETDELETEBYID = 'skillsets/v1/deletebyid';
  SKILLSETSTATUSCHANGE = 'skillsets/v1/statuschanges';
  SKILLSETUPDATEBYID = 'skillsets/v1/updatebyid';
  GETSKILLSETBYID = 'skillsets/v1/getskillsetbyid/';
  ADDSKILLSET = 'skillsets/v1/addskillset';
  UPLOADEXCELSKILLSET = 'skillsets/v1/uploadexcel';

  SKILLSETSFORMADD = 'skillSetsForm/v1/add';
  SKILLSETSFORMUPDATEBYID = 'skillSetsForm/v1/updatebyid';
  SKILLSETSFORMDELETEBYID = 'skillSetsForm/v1/deletebyid';
  GETALLSKILLSETSFORM = 'skillSetsForm/v1/getalldata';
  GETSKILLSETSFORMBYID = 'skillSetsForm/v1/getbyid/';
  GETSKILLSETSFORMBYCOMPANYID = 'skillSetsForm/v1/getbycompanyid';
  GETSKILLSETSQUESTIONS = 'skillSetsForm/v1/getskillsets';
  GETSKILLSETSFORMSTATUSCHANGE = 'skillSetsForm/v1/statuschange';
  SHIFTUPDATE = 'attendanceTransaction/v1/shiftupdate';

  TESTMAIL = 'notificationPolicy/v1/TestEmail';

  MONTHLYSKILLSETSFORMADD = 'monthlySkillsetsform/v1/add';
  GETALLMONTHLYSKILLSETSFORM = 'monthlySkillsetsform/v1/getall';
  ADDSKILLSETSANSWERS = 'monthlySkillsetsform/v1/addSkillsetsAnswers';
  VERIFYSKILLSETSANSWERS = 'monthlySkillsetsform/v1/verifySkillsetsAnswers';
  GETBYMONTHLYSKILLSETSID = 'monthlySkillsetsform/v1/getbymonthlySkillsetsid/';
  GETMONTHLYSKILLSETSFORMUSERID = 'monthlySkillsetsform/v1/getbyuserid';
  UPDATESKILLSETSANSWERS = 'monthlySkillsetsform/v1/updateaSkillsetsAnswers';
  UPLOADUSEREXCEL = 'companycontact/v1/uploaduserExcel';
  EXPORTUSEREXCEL = 'companycontact/v1/exportuserExcel';
  ADDPANPHOTO = 'empJoining/v1/addpanphoto';
  GETALLSKILLSETSUSINGCOMPANYID = 'skillsets/v1/getAllSkillSetUsingCompanyID';

  GETMONTHLYSKILLSETSFORMREPORTTOID = 'monthlySkillsetsform/v1/getbyreporttoid';

  LOANUSERREQUEST = 'loanmaster/v1/addrequest';
  LOANSTATUSREQUEST = 'loanmaster/v1/changestatusrequest';
  GETALLLETTERS = 'userLetters/v1/getAllUserLetter';
  ADDLETTERS = 'userLetters/v1/add';
  DELETEUSERLETTER = 'userLetters/v1/delete';

  UPDATEUSERLETTER = 'userLetters/v1/postUpdateUserLetters';

  CREATEREQUESTADVANCEPAYMENT = 'advancePayment/v1/addd';
  POSTSTATUSREQUEST = 'advancePayment/v1/statusreq';

  SKILLSETSREPORT = 'monthlySkillsetsform/v1/skillsetsReport';

  UPDATEDEMPTRACKINGDATA = 'usertracking/v1/updatebyid';
  EDITEMPTRACKING = 'usertracking/v1/getbyid';
  USERATTENDANCESUMMARY = 'attendanceTransaction/v1/userAttendanceSummary/';
  GETALLATTENDACECORRECTION = 'attendanceCorrection/v1/getAlldata/';

  BIRTHDATE = 'auth/v1/birthday';
  ANNIVERSARYDATE = 'auth/v1/anniversary';

  USERADDRESSVERIFYREQ = 'useraddress/v1/verifyreq';
  DOCVERIFYREQ = 'userdocument/v1/verifyreq';
  EXPERIENCEVERIFYREQ = 'userexperience/v1/verifyreq';
  EDUCATIONVERIFYREQ = 'usereducation/v1/verifyreq';
  GETALLUSERFAMILYDATA = 'userfamily/v1/getalldata';
  POSTVERIFYREQ = 'userfamily/v1/verifyreq';

  GETUSERADDRESSBYID = 'useraddress//v1/getbyid/';
  GETUSEREXPERIENCEBYID = 'userexperience/v1/getbyid/';
  GETUSEREDUCATIONBYID = 'usereducation/v1/getbyid/';
  GETUSERFAMILYBYID = 'userfamily/v1/getbyid/';
  GETUSERDOCUMENTBYID = 'userdocument/v1/getbyid/';

  ISSUESALARYSLIP = 'salaryTrans/v1/issueSalarySlip';

  CREATEMODULE = 'moduleList/v1/add';
  UPDATEMODULELIST = 'moduleList/V1/updatebyid';
  VIEWMODULELIST = 'moduleList/V1/getbyid/';
  DELETEMODULE = 'moduleList/V1/deletebyid';
  GETALLMODULE = 'moduleList/V1/getalldata';
  MODULESTATUS = 'moduleList/V1/statuschanges';
  UPLOADMODULEEXCELDATA = 'moduleList/v1/uploadexcel';

  CREATEMODULEDETAILS = 'moduleDetails/v1/add';
  UPDATEMODULEDETAILS = 'moduleDetails/V1/updatebyid';
  VIEWMODULEDETAILS = 'moduleDetails/V1/getbyid/';
  DELETEMODULEDETALS = 'moduleDetails/V1/deletebyid';
  GETALLMODULEDETALS = 'moduleDetails/V1/getalldata';
  MODULEDETAILSSTATUS = 'moduleDetails/V1/statuschanges';
  UPLOADMODULEDETAILSEXCEL = 'moduleDetails/v1/uploadexcel';
  GETUSERSBYDEPARTMENTDESIGNATION = 'companycontact/v1/getUserbyBranchDepartment';
  CREATEPOLICYDOCUMENT = 'policyDocument/v1';
  GETALLPOLICYDOCUMENT = 'policyDocument/v1';
  GETONEPOLICYDOCUMENT = 'policyDocument/v1/';
  UPDATEPOLICYDOCUMENT = 'policyDocument/v1/';
  DELETEPOLICYDOCUMENT = 'policyDocument/v1/';

  CREATETICKET = 'ticket/v1';
  GETALLTICKET = 'ticket/v1';
  GETONETICKET = 'ticket/v1/';
  UPDATETICKET = 'ticket/v1/';
  // DELETETICKET = "ticket/v1/";

  CREATETICKETCATEGORY = 'ticketCategory/v1';
  GETALLTICKETCATEGORY = 'ticketCategory/v1';
  GETONETICKETCATEGORY = 'ticketCategory/v1/';
  UPDATETICKETCATEGORY = 'ticketCategory/v1/';
  DELETETICKETCATEGORY = 'ticketCategory/v1/';

  CREATETICKETSUBCATEGORY = 'ticketSubCategory/v1';
  GETALLTICKETSUBCATEGORY = 'ticketSubCategory/v1';
  GETONETICKETSUBCATEGORY = 'ticketSubCategory/v1/';
  UPDATETICKETSUBCATEGORY = 'ticketSubCategory/v1/';
  DELETETICKETSUBCATEGORY = 'ticketSubCategory/v1/';
  CALCULATEOVERTIME = 'overTime/v1/calculateOvertime';

  GETALLAUTHDETAILS = 'AuthorizationDetails/v1/getAuthList';
  REPLACEAUTHDETAILS = 'AuthorizationDetails/v1/replaceAuth';

  ADDUSERCHECKLIST = 'usercheckList/v1/AddUserChecklist';
  GETUSERCHECKLISTBYID = 'usercheckList/v1/getByIdUserChecklist/';
  UPDATEUSERCHECKLIST = 'usercheckList/v1/updateByIdUserChecklist';
  GETALLUSERCHECKLIST = 'usercheckList/v1/getByUserChecklist';
  GETALLCHECKISTBYUSER = 'usercheckList/v1/getCheckListbyUserID/';
  GETALLCHECKISTQBYUSER = 'checkListQuestion/v1/getCheckListQuestionByuserId/';

  GETALLCHECKLISTDATA = 'checkList/v1/getAllCheckList';
  CHANGESTATUSCHECKLIST = 'checkList/v1/poststatuschange';
  CREATECHECKLISTDATA = 'checkList/v1/postAddCheckList';
  VIEWCHECKLISTDATA = 'checkList/v1/getCheckListById/';
  UPDATECHECKLIST = 'checkList/v1/postUpdateCheckList';

  CREATECHECKLISTQUESTIONDATA = 'checkListQuestion/v1/postAddCheckListQuestion';
  GETALLCHECKLISTQUESTIONDATA = 'checkListQuestion/v1/getAllCheckListQuestion';
  CHANGESTATUSCHECKLISTQUESTION = 'checkListQuestion/v1/poststatuschangeCheckListQuestion';
  UPDATECHECKLISTQUESTION = 'checkListQuestion/v1/postUpdateCheckListQuestion';
  GETBYIDCHECKLISTQUESTION = 'checkListQuestion/v1/getCheckListQuestionById/';
  LEAVEREPORT = 'report/v1/leaveReport';
  TICKETDASHBOARD = 'ticket/v1/report';
  LISTADMINCHECKLIST = 'usercheckList/v1/listAdminCheckList';
  PENDINGREQUEST = 'leaveauthorizationRequest/v1/countpending';
  UPDATEVIEWSTATUS = 'loanmaster/v1/updateviewstatus';
  UPDATEVIEWSTATUSLEAVE = 'leaveauthorizationRequest/v1/updateViewStatus';
  IDCARDDETAILS = 'empJoining/v1/employeeIdCard';

  ADDROLEMASTER = 'roleMaster/v1/postAddRoleMaster';
  GETROLEMASTERBYID = 'roleMaster/v1/getRoleMasterById/';
  UPDATEROLEMASTER = 'roleMaster/v1/postUpdateRoleMaster';
  LISTROLEMASTER = 'roleMaster/v1/listRoleMaster';

  ASSIGNROLE = 'roleMaster/v1/AssignRoleMaster';
  LISTUSERPERMISSION = 'roleMaster/v1/listUserPermission';

  GETPENDINGOVERTIME = 'overTime/v1/getPendingOvertimedata';
  PROFILESTATUS = 'empJoining/v1/profileStatus';
  REQUESTCOUNTHRDASHBOARD = 'leaveauthorizationRequest/v1/requestCountforHRdashboard';
  DASHBOARDSALARY = 'report/v1/getDashboardSalary';

  EXPENSEREPORT = 'report/v1/expense-report';

  GETALLSALARYSTRUCTUREBYUSERID = 'hrSalaryMaster/V1/';
  DASHBOARDPUNCHINOUTGRAPH = 'attendanceTransaction/v1/dashboardpunchinoutNEW';
  DASHBOARDMONTHLYATTENDACE = 'attendanceTransaction/v1/dashboardattendanceNEW';
  DASHBOARDMISSPUNCHREPORT = 'attendanceTransaction/v1/dashboardMisspunchReport';

  PENDNGBIOMETRICSYNC = 'biometric/v1/pendingbiometricsync';
  GETTRACKINGREPORT = 'report/v1/getTrackingReports';

  GETUSERLIST = 'userchats/v1/getuserList';
  POSTSENDMESSAGE = 'userchats/v1/postSendMessage';
  POSTUPDATECHATSTOSEEN = 'userchats/v1/postUpdatechatstoseen';
  GETUSERWISECHAT = 'userchats/v1/getuserWiseChat';
  POSTDELETECHAT = 'userchats/v1/postdeletechat';

  CHECKSUBSCRIPTIONPLANEXPIRATION = 'subscription/v1/getSubscriptionPlanExpiration';
  LATEINEARLYGOREPORT = 'attendanceTransaction/v1/LateComeEarlyGo';

  PREBOARDINGFORMDOWNLOAD = 'preboarding/v1/preBoardingFormDownload';
  GETOVERTIMECONSOLIDATEREPORT = 'overTime/v1';
  GETUSERBYCOMPANYANDDATERANGE = 'companycontact/v1/getUserbyCompanyandDateRange';

  GETUSERBYBRANCHANDDATERANGE = 'companycontact/v1/getUserByBranchandDateRange';
  GENERATELEAVEEXCEL = 'attendanceTransaction/v1/getLeaveExcel';

  SENTIMENTPUNCHINAPI = 'sentimentPunchIn/v1';
  SENTIMENTANALYSIS = 'sentimentPunchIn/v1/analysis';

  GETWORKINGLOCATION = 'workingLocation/v1/getworkinglocationReports';
  DELETEWORKINGLOCATION = 'workingLocation/v1/deletebyid';
  WORKINGLOCATIONSTATUSCHANGE = 'workingLocation/v1/statuschange';
  CREATEWORKINGLOCATION = 'workingLocation/v1/add';
  VIEWWORKINGLOCATION = 'workingLocation/v1/getbyid/';
  UPDATEWORKINGLOCATION = 'workingLocation/v1/updatebyid';
  UPLOADEXCELWORKINGLOCATION = 'workingLocation/v1/uploadexcel';

  ADDEMPLOYEEWORKINGLOCATION = 'employeeWorkingLocation/v1/add';
  GETWORKINGLOCATIONBYUSERID = 'employeeWorkingLocation/v1/getbyuserid/';
  DELETEEMPLOYEEWORKINGLOCATION = 'employeeWorkingLocation/v1/deletebyid';
  GETEMPLOYEEWORKINGLOCATION = 'employeeWorkingLocation/v1/getEmployeeWorkingLocation';
  ADDBULKEMPLOYEEWORKINGLOCATION = 'employeeWorkingLocation/v1/addbulk';

  GETLEAVEBALANCESUMMARYREPORT = 'report/v1/getleaveBalanceReport';
  GETVARIABLEDEMOEXCEL = 'salaryTrans/v1/getVariableDemoExcel';
  UPLOADVARIABLEEXCEL = 'salaryTrans/v1/uploadVariablePayheadExcel';
  ADDINCOMETAXSLABMASTER = 'incomeTaxSlabMaster/v1/add';
  UPDATEINCOMETAXSLABMASTER = 'incomeTaxSlabMaster/v1/';
  UPDATESTATUSINCOMETAXSLABMASTER = 'incomeTaxSlabMaster/v1/updatestatus/';
  GETALLDATAINCOMETAXSLABMASTER = 'incomeTaxSlabMaster/v1/getAllData';
  GETBYIDINCOMETAXSLABMASTER = 'incomeTaxSlabMaster/v1/getById/';

  ADDINCOMETAXSLAB = 'incomeTaxSlabs/v1/add';
  UPDATEINCOMETAXSLAB = 'incomeTaxSlabs/v1/';
  UPDATESTATUSINCOMETAXSLAB = 'incomeTaxSlabs/v1/updatestatus/';
  GETALLDATAINCOMETAXSLAB = 'incomeTaxSlabs/v1/getAllData';
  GETBYIDINCOMETAXSLAB = 'incomeTaxSlabs/v1/getById/';

  DESHBOARDCHECK = 'auth/v1/dashboardcheck';
  ATTENDACESTATUS = 'attendanceTransaction/v1/attendancestatus';
  ATTENDACEAPI = 'attendanceTransaction/v1/attendanceApi';

  GETCHECKLISTBYUSERDATE = 'usercheckList/v1/getByDateandUserIDUserChecklist';
  GETCOUNTWISEATTENDANCEDATA = 'hrleavesmonthlytrans/v1/demoCountWiseAttendance';
  UPLOADCOUNTWISEATTENDANCE = 'hrleavesmonthlytrans/v1/uploadCountWiseAttendance';

  ADDLATEEARLYPOLICY = 'LateEarlyPolicy/v1/add';
  UPDATEEARLYPOLICY = 'LateEarlyPolicy/v1/update';
  GETBYIDEARLYPOLICY = 'LateEarlyPolicy/v1/getbyid/';
  STATUSCHANGEEARLYPOLICY = 'LateEarlyPolicy/v1/statuschange';
  GETALLEARLYPOLICY = 'LateEarlyPolicy/v1/list';

  ADDEMPLOYEELATEEARLYPOLICY = 'EmployeeLateEarlyPolicyRoutes/v1/add';
  GETBYUSERIDELATEEARLYPOLICY = 'EmployeeLateEarlyPolicyRoutes/v1/getbyuserid/';
  UPDATEELATEEARLYPOLICY = 'EmployeeLateEarlyPolicyRoutes/v1/updatebyid';
  DELETEELATEEARLYPOLICY = 'EmployeeLateEarlyPolicyRoutes/v1/deletebyid';
  GETALLELATEEARLYPOLICY = 'EmployeeLateEarlyPolicyRoutes/v1/getLateEarlyPolicy';
  ADDBULKELATEEARLYPOLICY = 'EmployeeLateEarlyPolicyRoutes/v1/postAddAllPolicyBULK';

  PERDAYCOSTREPORT = 'attendanceTransaction/v1/getPerDayCostDepartmentWise';
  LOANDATASHOW = 'loanmaster/v1/loandatashow';
  ADVANCEDATASHOW = 'advancePayment/v1/advancedatashow';
  LEAVEDATASHOW = 'leaveauthorizationRequest/v1/leavedatashow';
  OVERTIMEDATASHOW = 'authorizationRequest/v1/overtimedatashow';
  EXPENSEDATASHOW = 'authorizationRequest/v1/expensedatashow';

  SEARCHUSERPAGES = 'auth/v1/user-pages';

  GETUSERLEAVEBALANCE = 'userleave/v1/getUserLeaveBalance';

  GETUSERINBOXDATA = 'userInbox/v1/getUserInboxData';

  BANKREPORT = 'report/v1/bankStatementReport';
  MAILSALARYSLIP = 'salaryTrans/v1/sendSalarySlipviamail';

  ATTENDANCEREPORT = 'attendanceTransaction/v1/AttendanceReportMain';

  GETLEAVEAUTHORIZATIONREQUESTBYAUTHORIZATIONREQUESTID =
    'leaveauthorizationRequest/v1/getAuthorizationRequestByAuthorizationRequestId/';

  GETEXPENSEAUTHORIZATIONREQUESTBYAUTHORIZATIONREQUESTID =
    'authorizationRequest/v1/getExpenseAuthorizationRequestByAuthorizationRequestId/';

  USERSKILLSETSREPORT = 'monthlySkillsetsform/v1/userSkillsetsReport';

  GETDAILYHOURLYREPORT = 'attendanceTransaction/v1/getDailyHourlyReport';
  DISTINCTLOCATIONSTRACKINGREPORT = 'report/v1/distinctLoationsTrackingReport';
  FIVEMINUTEGAPYRACKINGREPORT = 'report/v1/fiveMinuteGapTrackingReport';


  CREATEKRA = 'kra/v1';
  GETALLKRA = 'kra/v1';
  GETONEKRA = 'kra/v1/';
  UPDATEKRA = 'kra/v1/';
  DELETEKRA = 'kra/v1/';

  GETALLKPI = 'kpi/v1';
  DELETEKPI = 'kpi/v1/';
  CREATEKPI = 'kpi/v1';
  GETONEKPI = 'kpi/v1/';
  UPDATEKPI = 'kpi/v1/';

  GETALLGOAL = 'goal/v1';
  DELETEGOAL = 'goal/v1/';
  CREATEGOAL = 'goal/v1';
  GETONEGOAL = 'goal/v1/';
  UPDATEGOAL = 'goal/v1/';

  GETALLEMPLOYEEGOAL = 'employeeGoal/v1';
  BULKCREATEEMPLOYEEGOAL = 'employeeGoal/v1';
  UPDATEEMPLOYEEGOAL = 'employeeGoal/v1/';
  GETONEEMPLOYEEGOAL = 'employeeGoal/v1/';
  DELETEEMPLOYEEGOAL = 'employeeGoal/v1/';

  GETALLGOALSETTING = 'goalSetting/v1';
  DELETEGOALSETTING = 'goalSetting/v1/';
  CREATEGOALSETTING = 'goalSetting/v1';
  UPDATEGOALSETTING = 'goalSetting/v1/';
  GETONEGOALSETTING = 'goalSetting/v1/';

  GETALLPMSPOLICY = 'pmsPolicy/v1';
  DELETEPMSPOLICY = 'pmsPolicy/v1/';
  CREATEPMSPOLICY = 'pmsPolicy/v1';
  UPDATEPMSPOLICY = 'pmsPolicy/v1/';
  GETONEPMSPOLICY = 'pmsPolicy/v1/';

  ADDTDSSECTION = 'tdsSection/v1/add';
  UPDATETDSSECTION = 'tdsSection/v1/';
  UPDATESTATUSTDSSECTION = 'tdsSection/v1/updatestatus/';
  GETALLDATATDSSECTION = 'tdsSection/v1/getAllData';
  GETBYIDTDSSECTION = 'tdsSection/v1/getById/';
  UPLOADSECTIONEXCEL = 'tdsSection/v1/uploadSection';

  ADDTDSSUBSECTION = 'tdsSubSection/v1/add';
  UPDATETDSSUBSECTION = 'tdsSubSection/v1/';
  UPDATESTATUSTDSSUBSECTION = 'tdsSubSection/v1/updatestatus/';
  GETALLDATATDSSUBSECTION = 'tdsSubSection/v1/getAllData';
  GETBYIDTDSSUBSECTION = 'tdsSubSection/v1/getById/';
  UPLOADSUBSECTIONEXCEL = 'tdsSubSection/v1/uploadSubSection';


  GETEMPPENALTYBYID = 'emppenalty/v1/getbyid/';


  GETEMPLOYEEREPOTSTOREPORT = 'report/v1/getreportsToreport'
  GETTALEATTREPORT = 'report/v1/getTaleAttreport'

  // employeetaxregime

  ADDEMPLOYEETAXREGIME = 'employeeTaxRegime/v1/add';
  UPDATEEMPLOYEETAXREGIME = 'employeeTaxRegime/v1/';
  DELETEEMPLOYEETAXREGIME = 'employeeTaxRegime/v1/';
  GETEMPLOYEETAXREGIMEBYID = 'employeeTaxRegime/v1/getbyid/';
  GETEMPLOYEETAXREGIMELIST = 'employeeTaxRegime/v1/getlist';
  GETEMPFORADDREGIMELIST = 'employeeTaxRegime/v1/getnonRegimeUserlist';
  GETINCOMETAXREGIMEBYUSERID = 'employeeTaxRegime/v1/getByUserId';
  GETUSERBYSALARYPOLICY = 'employeesalarypolicy/v1/getUserBySalaryPolicy';

  // tds sub section category

  ADDTDSSUBSECTIONCATEGORY = 'tdsSubSectionCategory/v1/add';
  UPDATETDSSUBSECTIONCATEGORY = 'tdsSubSectionCategory/v1/update/';
  DELETETDSSUBSECTIONCATEGORY = 'tdsSubSectionCategory/v1/delete/';
  GETTDSSUBSECTIONCATEGORYBYID = 'tdsSubSectionCategory/v1/getById/';
  GETTDSSUBSECTIONCATEGORYLIST = 'tdsSubSectionCategory/v1/getlist';

  // employee declaration

  ADDEMPLOYEEDECLARATION = 'employeeDeclaration/v1/add';
  GETEMPLOYEEDECLARATIONBYID = 'employeeDeclaration/v1/getbyid/';
  UPDATEEMPLOYEEDECLARATION = 'employeeDeclaration/v1/update';
  DELETEEMPLOYEEDECLARATION = 'employeeDeclaration/v1/delete';
  GETEMPLOYEEDECLARATIONLIST = 'employeeDeclaration/v1/getAll';
  ACCEPTREJECTEMPLOYEEDECLARATION = 'employeeDeclaration/v1/acceptReject';
  GETEMPLOYEEDECLARATIONBYUSERID = 'employeeDeclaration/v1/getByUserId';
  GETEMPLOYEEDECLARATIONDATAILS = 'employeeDeclaration/v1/getDeclarationDatailsByUserId';
  GETYEARLYEMPLOYEEINCOMETAX = 'employeeDeclaration/v1/getYearlyIncometaxCalculationByUserId';
  GETFINANCIALYEARS = 'employeeDeclaration/v1/getFinancialYear';

  GETMONTHLYTAXDEDUCTIONSOFEMPLOYEES = 'employeeDeclaration/v1/getMonthlyTaxDeductionsOfEmployees';

  ADDMANUALINCOMETAXAMOUNT = 'employeeDeclaration/v1/addManualInxomeTaxAmount';

  DELETEMANUALINCOMETAXAMOUNT = 'employeeDeclaration/v1/deleteManualAmount';

  GETINCOMETAXCOMPUTATION = 'employeeDeclaration/v1/getIncomeTaxComputation';

  // Review Form
  GETALLREVIEWFORM = 'reviewForm/v1';
  GETONEREVIEWFORM = 'reviewForm/v1/';
  DELETEREVIEWFORM = 'reviewForm/v1/';
  CREATEREVIEWFORM = 'reviewForm/v1';
  UPDATEREVIEWFORM = 'reviewForm/v1/';

  // Review Form Answer
  CREATEUPDATEREVIEWFORMANSWERS = 'reviewFormAnswer/v1';
  GETONEREVIEWFORMANSWERS = 'reviewFormAnswer/v1/';
  GETALLREVIEWFORMANSWERS = 'reviewFormAnswer/v1';

  GETALLSALARYSLIP = 'salaryTrans/v1/getAllSalarySlip';

  UPLOADPRODUCTEXCEL = 'product/v1/uploadexcel';



  //Performance Review
  GETALLPERFORMANCEREVIEW = 'performanceReview/v1';
  GETONEPERFORMANCEREVIEW = 'performanceReview/v1/';
  DELETEPERFORMANCEREVIEW = 'performanceReview/v1/';
  CREATEPERFORMANCEREVIEW = 'performanceReview/v1';
  UPDATEPERFORMANCEREVIEW = 'performanceReview/v1/';

  // Employee Performance Review

  GETALLEMPLOYEEPERFORMANCEREVIEW = 'employeePerformanceReview/v1';
  GETONEEMPLOYEEPERFORMANCEREVIEW = 'employeePerformanceReview/v1/';
  DELETEEMPLOYEEPERFORMANCEREVIEW = 'employeePerformanceReview/v1/';
  CREATEEMPLOYEEPERFORMANCEREVIEW = 'employeePerformanceReview/v1';
  UPDATEEMPLOYEEPERFORMANCEREVIEW = 'employeePerformanceReview/v1/';
  EMPLOYEEPERFORMANCEREVIEWREPORT = 'employeePerformanceReview/v1/report';

  HEERAGROUPBIOMETRICSYNC = 'biometric/v1/heeraGroupBiometric';

  GETADVANCEDEMOEXCEL = 'advancePayment/v1/exportDemoexcel';
  UPLOADADVANCE = 'advancePayment/v1/uploadExcel';

  ADDMANUALLOG = 'biometric/v1/addManualLog';

  //Employee Goal Review
  GETALLEMPLOYEEGOALREVIEW = 'employeeGoalReview/v1';
  GETONEEMPLOYEEGOALREVIEW = 'employeeGoalReview/v1/';
  DELETEEMPLOYEEGOALREVIEW = 'employeeGoalReview/v1/';
  CREATEEMPLOYEEGOALREVIEW = 'employeeGoalReview/v1';
  UPDATEEMPLOYEEGOALREVIEW = 'employeeGoalReview/v1';
  EMPLOYEEGOALREVIEWREPORT = 'employeeGoalReview/v1/report';
  ADDEMPLOYEEGOALREVIEW = 'employeeGoalReview/v1/add-review';

  ADDPREBOARDINGMASTER = 'preboarding/v1/addPreboardingMaster';
  UPDATEPREBOARDINGMASTER = 'preboarding/v1/updatePreboardingMaster';
  DELETEPREBOARDINGMASTER = 'preboarding/v1/deletePreboardingMaster';
  GETPREBOARDINGMASTER = 'preboarding/v1/getPreboardingMaster';

  ADDOFFERLETTER = 'offerLetter/v1/add';
  UPDATEOFFERLETTER = 'offerLetter/v1/update';
  DELETEOFFERLETTER = 'offerLetter/v1/delete';
  GETOFFERLETTER = 'offerLetter/v1/get';
  GENERATEOFFERLETTER = 'preboarding/v1/getPreboardingOfferLetter';

  // Employee Gatepass
  GETALLEMPLOYEEGATEPASS = 'employeeGatepass/v1';
  DELETEEMPLOYEEGATEPASS = 'employeeGatepass/v1/';
  CREATEEMPLOYEEGATEPASS = 'employeeGatepass/v1';
  GETONEEMPLOYEEGATEPASS = 'employeeGatepass/v1/';
  UPDATEEMPLOYEEGATEPASS = 'employeeGatepass/v1/';
  UPDATECHECKINOUTGATEPASS = 'employeeGatepass/v1/update';

  UPDATEPREBOARDINGOFFERLETTER = 'preboarding/v1/updateOfferLetter';

  DAILYINOUTREPORT = 'report/v1/dailyInOutReport';

  // employee leave policy

  GETEMPLOYEELEAVEPOLICYDATA = 'employeeLeavePolicy/v1/';
  GETHRLEAVEDATA = 'employeeLeavePolicy/v1/getdatabyusercompany/';
  EMPLOYEELEAVEPOLICYSTATUSCHANGES = 'employeeLeavePolicy/v1/statuschange';
  DELETEEMPLOYEELEAVEPOLICY = 'employeeLeavePolicy/v1/';
  CREATEEMPLOYEELEAVEPOLICY = 'employeeLeavePolicy/v1';
  EDITEMPLOYEELEAVEPOLICY = 'employeeLeavePolicy/v1/';
  GETEMPLOYEELEAVEPOLICYBYID = 'employeeLeavePolicy/v1/';

  //company notification setup

  ADDCOMPANYNOTIFICATIONSETUP = 'companyNotificationSetup/v1/addData';
  UPDATECOMPANYNOTIFICATIONSETUP = 'companyNotificationSetup/v1/updateData/';
  GETCOMPANYNOTIFICATIONSETUPBYID = 'companyNotificationSetup//v1/getById/';
  GETCOMPANYNOTIFICATIONSETUPLIST = 'companyNotificationSetup/v1/getlist';
  DELETECOMPANYNOTIFICATIONSETUPBYID = 'companyNotificationSetup/v1/deleteById/';

  ADDSNCODES = 'SN_Code/v1/add';
  UPDATESNCODES = 'SN_Code/v1/update';
  STATUSCHANGESNCODES = 'SN_Code/v1/statuschange';
  GETALLSNCODES = 'SN_Code/v1/getAll';
  GETSNCODESBYID = 'SN_Code/v1/getById/';
  UPLOADSNCODES = 'SN_Code/v1/uploadSNCodes';


  // division

  ADDDIVISION = 'division/v1/addData'
  UPDATEDIVISION = 'division/v1/updateData/'
  LISTDIVISION = 'division/v1/listData'
  DELETEDIVISION = 'division/v1/deleteData/'
  GETDIVISIONBYID = 'division/v1/getById/'
  UPLOADDIVISIONEXCEL = 'division/v1/uploadExcel'
  DEMODIVISIONEXCEL = 'division/v1/demoExcel'
  UPDATEDIVISIONSTATUS = 'division/v1/updateStatus'

  // EMPLOYEE DIVISION

  ADDEMPLOYEEDIVISION = 'employeeDivision/v1/addData'
  GETEMPLOYEEDIVISIONBYUSERID = 'employeeDivision/v1/getByUserId/'
  BULKADDEMPLOYEEDIVISION = 'employeeDivision/v1/addbulk'
  GETDIVISIONBYCOMPANYID = 'employeeDivision/v1/getDataBycompanyId/'
  GETEMPLOYEEDIVISIONDATA = 'employeeDivision/v1/getEmployeeDivisionByCompany'

  // WORKING AREA

  ADDWORKINGAREA = 'workingArea/v1/addData'
  UPDATEWORKINGAREA = 'workingArea/v1/updateData/'
  LISTWORKINGAREA = 'workingArea/v1/listData'
  DELETEWORKINGAREA = 'workingArea/v1/deleteData/'
  GETWORKINGAREABYID = 'workingArea/v1/getById/'
  UPLOADWORKINGAREAEXCEL = 'workingArea/v1/uploadExcel'
  DEMOWORKINGAREAEXCEL = 'workingArea/v1/demoExcel'
  UPDATEWORKINGAREASTATUS = 'workingArea/v1/updateStatus'


  // EMPLOYEE WORKING AREA

  ADDEMPLOYEEWORKINGAREA = 'employeeWorkingArea/v1/addData'
  GETEMPLOYEEWORKINGAREABYUSERID = 'employeeWorkingArea/v1/getByUserId/'
  BULKADDEMPLOYEEWORKINGAREA = 'employeeWorkingArea/v1/addbulk'
  GETWORKINGAREABYCOMPANYID = 'employeeWorkingArea/v1/getDataBycompanyId/'
  GETEMPLOYEEWORKINGAREADATA = 'employeeWorkingArea/v1/getEmployeeWorkingAreaByCompany'

  MONTHLYATTENDANCEREPORT = 'report/v1/monthlyAttendanceReport'

  EMPLOYEEMONTHWISESALRYREPORT = 'report/v1/employeeWiseSalaryReport'

  DEMOEXCELOFEMPLOYEEDIVISION = 'employeeDivision/v1/downloadDemoExcel'
  UPLOADEMPLOYEEDIVISIONEXCEL = 'employeeDivision/v1/uploadDivision'
  DEMOEXCELOFEMPLOYEEWORKINGAREA = 'employeeWorkingArea/v1/downloadDemoExcel'
  UPLOADEMPLOYEEWORKINGAREAEXCEL = 'employeeWorkingArea/v1/uploadWorkingArea'

  CHECKOPTIONALLEAVEOFUSER = 'userleave/v1/getOptionalLeaveByUserId'
  UPLOADASSET = 'assignasset/v1/uploadExcel'

  UPDATECUSTOMERPREFERENCE = 'companymaster/v1/postUpdateCustomerPreference'

  constructor() { }
}
