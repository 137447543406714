<div #glideRef class="glide basic">
  <div class="glide__track pb-3" data-glide-el="track">
    <div class="glide__slides" #glideSlides>
      <ng-content></ng-content>
    </div>
  </div>
  <div
    [ngClass]="{ 'd-none': settings.hideNav }"
    class="glide__arrows slider-nav"
    data-glide-el="controls"
  >
    <button class="glide__arrow glide__arrow--left left-arrow btn btn-link" data-glide-dir="<">
      <i class="simple-icon-arrow-left"></i>
    </button>
    <div class="glide__bullets slider-dot-container" data-glide-el="controls[nav]">
      <button
        *ngFor="let item of glideCount; let i = index"
        class="glide__bullet slider-dot"
        (click)="onBulletClick(i)"
      ></button>
    </div>
    <button class="glide__arrow glide__arrow--right right-arrow btn btn-link" data-glide-dir=">">
      <i class="simple-icon-arrow-right"></i>
    </button>
  </div>
</div>
