<style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 1rem;
    align-items: center;
    background-color: rgb(237, 233, 224);
  }

  .content {
    position: absolute;
    top: pagex;
    padding: 10px 20px 20px 20px;
    min-width: max-content;
    background-color: #ffffff;
    border-radius: 10px;
    opacity: 0;
    transform: translateY(-10px);
    transition: 0.5s;
    left: pagey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .content .header {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .content .header .img {
    grid-column: 1/2;
    width: 0px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }

  .content .header .infos {
    margin-left: 1em;
  }

  .content .header .infos .name {
    font-size: 1.25em;
    color: #000000;
    letter-spacing: 1px;
    font-size: 600;
  }

  .content .header .infos .title {
    font-size: 0.9em;
    color: #000000;
    font-size: 500 !important;
    margin-top: 5px;
  }

  .content .body {
    padding: 1em;
  }

  .content .body p {
    font-size: 0.95em;
    color: #000000;
  }

  .content {
    opacity: 1;
    transform: translateX(-15%) translateY(0px);
  }
</style>

<div class="content">
  <div *ngIf="show">
    <div (click)="onButtonClick()" style="display: flex; justify-content: end">
      <span style="font-size: 0.9em; color: #969696; font-size: 500 !important; cursor: pointer">
        &#10006;
      </span>
    </div>

    <div class="header">
      <span *ngIf="imgshow1">
        <img
          alt="img-responsive"
          class="list-thumbnail"
          style="border-radius: 50%; height: 60px !important; width: 60px !important"
          src="{{ photo }}"
        />
      </span>
      <span *ngIf="!imgshow1">
        <img
          alt="img-responsive"
          class="imgres"
          height="60px"
          width="60px"
          style="border-radius: 50%"
          src="/assets/img/profiles/l-2.jpg"
        />
      </span>

      <div class="infos">
        <h3 class="name">{{ displayName }}</h3>
        <p class="title" *ngIf="companyName">{{ companyName }}</p>
      </div>
    </div>

    <div class="header" style="margin-top: 4px">
      <div class="infos" style="margin-left: 2px">
        <p class="title iconsminds-smartphone-4" *ngIf="userNumber">{{ userNumber }}</p>
        <p class="title iconsminds-management" *ngIf="department">{{ department }}</p>
      </div>
      <div class="infos" style="margin-left: 2px">
        <p class="title iconsminds-engineering" *ngIf="designation">{{ designation }}</p>
        <p class="title iconsminds-location-2" *ngIf="branch">{{ branch }}</p>
      </div>
    </div>
  </div>
</div>
