<div [ngClass]="{ 'landing-page': true, 'show-mobile-menu': showMobileMenu }">
  <div class="mobile-menu" (click)="$event.stopPropagation()">
    <a (click)="scrollTo('#home')" href="javascript:;" class="logo-mobile">
      <span></span>
    </a>
    <ul class="navbar-nav">
      <li class="nav-item"><a (click)="scrollTo('#features')" href="javascript:;">FEATURES</a></li>
      <li class="nav-item"><a (click)="scrollTo('#layouts')" href="javascript:;">LAYOUTS</a></li>
      <li class="nav-item">
        <a (click)="scrollTo('#components')" href="javascript:;">COMPONENTS</a>
      </li>
      <li class="nav-item"><a (click)="scrollTo('#apps')" href="javascript:;">APPS</a></li>
      <li class="nav-item"><a (click)="scrollTo('#themes')" href="javascript:;">THEMES</a></li>
      <li class="nav-item">
        <div class="separator"></div>
      </li>
      <li class="nav-item text-center">
        <a class="btn btn-outline-primary btn-sm mobile-menu-cta" target="_blank" [href]="buyUrl">
          BUY
        </a>
      </li>
    </ul>
  </div>

  <div class="main-container">
    <ngx-headroom class="landing-page-nav">
      <nav>
        <div class="container d-flex align-items-center justify-content-between">
          <a class="navbar-logo pull-left" (click)="scrollTo('#home')" href="javascript:;">
            <span class="white"></span>
            <span class="dark"></span>
          </a>
          <ul class="navbar-nav d-none d-lg-flex flex-row">
            <li class="nav-item">
              <a (click)="scrollTo('#features')" href="javascript:;">FEATURES</a>
            </li>
            <li class="nav-item">
              <a (click)="scrollTo('#layouts')" href="javascript:;">LAYOUTS</a>
            </li>
            <li class="nav-item">
              <a (click)="scrollTo('#components')" href="javascript:;">COMPONENTS</a>
            </li>
            <li class="nav-item"><a (click)="scrollTo('#apps')" href="javascript:;">APPS</a></li>
            <li class="nav-item">
              <a (click)="scrollTo('#themes')" href="javascript:;">THEMES</a>
            </li>
            <li class="nav-item pl-4">
              <a
                class="btn btn-outline-semi-light btn-sm pr-4 pl-4"
                target="_blank"
                [href]="buyUrl"
              >
                BUY
              </a>
            </li>
          </ul>
          <span
            class="mobile-menu-button"
            (click)="showMobileMenu = !showMobileMenu; $event.stopPropagation()"
          >
            <i class="simple-icon-menu"></i>
          </span>
        </div>
      </nav>
    </ngx-headroom>

    <div class="content-container" id="home">
      <div class="section home">
        <div class="container">
          <div class="row home-row">
            <div class="col-12 d-block d-md-none">
              <a [href]="adminRoot" target="_blank">
                <img
                  alt="mobile hero"
                  class="mobile-hero"
                  src="/assets/img/landing-page/home-hero-mobile.png"
                />
              </a>
            </div>

            <div class="col-12 col-xl-4 col-lg-5 col-md-6">
              <div class="home-text">
                <div class="display-1">
                  MAGIC IS IN
                  <br />
                  THE DETAILS
                </div>
                <p class="white mb-5">
                  Vien is the combination of good design, quality code and attention for details.
                  <br />
                  <br />
                  We used same design language for components, layouts, apps and other parts of the
                  template.
                  <br />
                  <br />
                  Hope you enjoy it!
                </p>
                <a class="btn btn-light btn-xl mr-2 mb-2" [href]="adminRoot" target="_blank">
                  VIEW NOW
                  <i class="simple-icon-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-12 col-xl-7 offset-xl-1 col-lg-7 col-md-6 d-none d-md-block">
              <a [href]="adminRoot" target="_blank">
                <img alt="hero" src="/assets/img/landing-page/home-hero.png" />
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-12 p-0">
              <div class="home-carousel">
                <app-glide [settings]="slideSettings">
                  <div class="card" *ngFor="let f of slideItems">
                    <div class="card-body text-center">
                      <div>
                        <i class="{{ f.icon + ' large-icon' }}"></i>
                        <h5 class="mb-3 font-weight-semibold">{{ f.title }}</h5>
                      </div>
                      <div>
                        <p class="detail-text">{{ f.detail }}</p>
                      </div>
                    </div>
                  </div>
                </app-glide>
              </div>
            </div>
          </div>

          <div class="row">
            <a
              class="btn btn-circle btn-outline-semi-light hero-circle-button"
              (click)="scrollTo('#features')"
            >
              <i class="simple-icon-arrow-down"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="container" id="features">
          <div class="row">
            <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
              <h1>Features At a Glance</h1>
              <p>
                We tried to create an admin theme that we would like to use ourselves so we listed
                our priorities. We would like to have a theme that is not over complicated to use,
                does the job well, contains must have components and looks really nice.
              </p>
            </div>
          </div>

          <div *ngFor="let feature of features; let i = index">
            <div *ngIf="i % 2 == 0" class="row feature-row">
              <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                <div class="feature-text-container">
                  <h2>{{ feature.title }}</h2>
                  <p [innerHTML]="feature.detail"></p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 position-relative">
                <img
                  [alt]="feature.title"
                  [src]="feature.img"
                  class="feature-image-right feature-image-charts position-relative"
                />
              </div>
            </div>
            <div *ngIf="i % 2 == 1" class="row feature-row">
              <div class="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                <img
                  [alt]="feature.title"
                  [src]="feature.img"
                  class="feature-image-left feature-image-charts"
                />
              </div>
              <div
                class="col-12 col-md-6 offset-md-0 col-lg-5 offset-lg-1 d-flex align-items-center order-1 order-md-2"
              >
                <div class="feature-text-container">
                  <h2>{{ feature.title }}</h2>
                  <p [innerHTML]="feature.detail"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section background">
        <div class="container" id="layouts">
          <div class="row">
            <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
              <h1>Structures & Layouts</h1>
              <p>
                We did our best to create layouts for various needs that developers might have and
                best experience for users.
                <br />
                They are clean and slick. They function well and look good at the same time.
              </p>
            </div>
          </div>

          <div class="row pt-5">
            <div class="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5" *ngFor="let l of layouts">
              <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" [src]="l.img" />
              <h4 class="text-center">{{ l.title }}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="section mb-0">
        <div class="container" id="components">
          <div class="row mb-5">
            <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
              <h1>Components</h1>
              <p>
                We used most popular and well managed open source components with bootstrap
                components. Combined them into even more useful ones. Themed them with same design
                principles and created a design harmony between components and layouts.
                <br />
                <br />
                From carousels to charts, switches to list we tried to provide components that we
                like to use on our development processes.
              </p>
            </div>
          </div>
        </div>
        <img class="components-image mb-5 pb-5" src="/assets/img/landing-page/components.jpg" />
      </div>

      <div class="section background">
        <div class="container" id="apps">
          <div class="row">
            <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center mb-4">
              <h1>Applications</h1>
              <p class="section-text">
                With the help of components and layouts, we created four different applications.
                They are a good way to get you started if you want to build something similar.
              </p>
            </div>
          </div>
          <div class="row screenshots">
            <div class="col-12 text-center mb-4">
              <tabset class="nav nav-tabs justify-content-center">
                <tab *ngFor="let app of applications" [heading]="app.title" class="card-body pt-0">
                  <a [routerLink]="app.path">
                    <img [alt]="app.title" [src]="app.img" class="app-image" />
                  </a>
                </tab>
              </tabset>
            </div>
          </div>
        </div>
      </div>

      <div class="section mb-0">
        <div class="container" id="themes">
          <div class="row mb-5">
            <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
              <h1>Themes</h1>
              <p>
                We carefully choosed colors and created 10 different themes with dark and light
                versions. You may also create your own themes easily since all the theme related
                styling is managed by Sass variables.
              </p>
            </div>
          </div>
          <div class="row mb-5" *ngFor="let t of themes">
            <div class="col-12 text-center mb-3">
              <h4 class="text-center">{{ t.title }}</h4>
            </div>
            <div class="col-12 col-md-6 col-lg-4 offset-lg-2 mb-3">
              <div class="depth-2 color-container">
                <div
                  *ngFor="let align of ['left', 'center', 'right']; let i = index"
                  class="{{ t.class + '-light-' + (i + 1) + ' color-' + align }}"
                ></div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <div class="depth-2 color-container">
                <div
                  *ngFor="let align of ['left', 'center', 'right']; let i = index"
                  class="{{ t.class + '-dark-' + (i + 1) + ' color-' + align }}"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section background background-no-bottom mb-0 pb-0">
        <div class="container">
          <div class="row">
            <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
              <h1>Enjoying so Far?</h1>
              <p>Purchase Vien to get a fresh start with your new project.</p>
            </div>
            <div class="col-12 offset-0 col-lg-6 offset-lg-3 newsletter-input-container">
              <div class="text-center mb-3">
                <a class="btn btn-secondary btn-xl" target="_blank" [href]="buyUrl">BUY NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section footer mb-0">
        <div class="container">
          <div class="row footer-row">
            <div class="col-12 text-right">
              <a
                class="btn btn-circle btn-outline-semi-light footer-circle-button"
                (click)="scrollTo('#home')"
                href="javascript:;"
              >
                <i class="simple-icon-arrow-up"></i>
              </a>
            </div>
            <div class="col-12 text-center footer-content">
              <a (click)="scrollTo('#home')" href="javascript:;">
                <img class="footer-logo" alt="footer logo" src="/assets/logos/white-full.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="container copyright pt-5 pb-5">
          <div class="row">
            <div class="col-12"></div>
            <div class="col-12 text-center">
              <p class="mb-0">2020 © ColoredStrategies</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
