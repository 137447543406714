import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-session-time-out',
  templateUrl: './session-time-out.component.html',
  styleUrls: ['./session-time-out.component.scss'],
})
export class SessionTimeOutComponent implements OnInit {
  @ViewChild('showClick') showClick: ElementRef;

  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  isModalOpen = false;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.getRefreshSessionTimeOutObservable().subscribe(() => {
      if (!this.isModalOpen) {
        this.showClick.nativeElement.click();
        this.isModalOpen = true; // Set to true once the modal is opened
      }
    });
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, this.config);
  }

  redirect() {
    localStorage.clear();
    window.open('https://tankhwapatra.co.in/');
    window.location.reload();
  }
}
