import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { OrgchartModule } from '@dabeng/ng-orgchart';
import { CompanyStructureComponent } from './company-structure/company-structure.component';
import { SessionTimeOutComponent } from './session-time-out/session-time-out.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChatNotificationComponent } from './chat-notification/chat-notification.component';
import { SimpleNotificationsModule } from 'angular2-notifications';

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    OrgchartModule,
    ModalModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    CompanyStructureComponent,
    SessionTimeOutComponent,
    ChatNotificationComponent,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
