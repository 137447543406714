<section *ngIf="chatBotShow == 1">
  <div class="{{ toggleClass }}">
    <div class="chat-container">
      <div class="chat-history" #chatHistory>
        <div
          class="chat-message"
          *ngFor="let message of messages"
          [ngClass]="{
            'user-message': message.sender === 'user',
            'bot-message': message.sender === 'bot'
          }"
        >
          <span [innerHTML]="message.content"></span>
        </div>
        <br />
      </div>
      <form #chatForm="ngForm">
        <input
          type="text"
          autocomplete="off"
          [(ngModel)]="userInput"
          name="userInput"
          placeholder="Type your message..."
        />
        <button type="submit" (click)="sendMessage()">
          <i class="simple-icon-cursor font"></i>
        </button>
        <button type="button" (click)="restart('exit', true)">
          <i class="iconsminds-reload-1 font2"></i>
        </button>
      </form>
    </div>
    <a (click)="toggleSwitcher($event)" class="c-pointer theme-button2 text-center items">
      <i class="simple-icon-earphones-alt icon"></i>
      <span class="icon-font-size">Can i help You?</span>
    </a>
  </div>
</section>
