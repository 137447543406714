import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, OnInit, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';
import { ConstantService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-company-structure',
  templateUrl: './company-structure.component.html',
  styleUrls: ['./company-structure.component.scss'],
})
export class CompanyStructureComponent implements OnInit {
  items: any;

  constructor(
    private router: Router,
    private notifications: NotificationsService,
    private api: ApiService,
    private constant: ConstantService,
    private http: HttpClient,
    public activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.allparentform();
  }
  allparentform() {
    this.api
      .callApi(this.constant.REPORTTO1 + localStorage.getItem('id'), {}, 'GET', true, false, true)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.items = res.data[0];
        }
      });
  }
}
