import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';

const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  menu: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

let data1;
let data2: any = localStorage.getItem('usertype');

if (data2 == 2) {
  const data: IMenuItem[] = [
    {
      icon: 'iconsminds-shop-4',
      label: 'menu.dashboards',
      menu: 'VisitPurpose',
      to: `${adminRoot}/dashboards/default`,
    },
    {
      icon: 'iconsminds-embassy',
      label: 'Admin Menu',
      menu: 'Company',
      to: `${adminRoot}/superadminmenus`,
      // roles: [UserRole.Admin],
    },
  ];
  data1 = data;
}
if (data2 == 3) {
  const data: IMenuItem[] = [
    {
      icon: 'iconsminds-shop-4',
      label: 'menu.dashboards',
      menu: 'VisitPurpose',
      to: `${adminRoot}/dashboards/default`,
    },
    {
      icon: 'iconsminds-embassy',
      label: 'Company',
      menu: 'Company',
      to: `${adminRoot}/masters/company_master`,
      // roles: [UserRole.Admin],
    },
  ];
  data1 = data;
}

if (data2 != 2 && data2 != 3) {
  const data: IMenuItem[] = [
    {
      icon: 'iconsminds-home',
      label: 'Home',
      menu: 'Home',
      to: `${adminRoot}/dashboards/analytics`,
    },
    {
      icon: 'iconsminds-male',
      label: 'Me',
      menu: 'Me',
      to: `${adminRoot}/me`,
    },
    {
      icon: 'iconsminds-books',
      label: 'Task',
      menu: 'Task',
      to: `${adminRoot}/tasks`,
    },

    {
      icon: 'iconsminds-notepad',
      label: 'Master',
      menu: 'Master',
      to: `${adminRoot}/masters`,
    },

    {
      icon: 'simple-icon-wrench',
      label: 'Service',
      menu: 'Master',
      to: `${adminRoot}/service`,
    },

    {
      icon: 'iconsminds-building',
      label: 'Visit',
      menu: 'VisitMain',
      to: `${adminRoot}/visits`,
    },
    {
      icon: 'iconsminds-business-mens',
      label: 'My Team',
      menu: 'MyTeam',
      to: `${adminRoot}/myteam`,
    },
    {
      icon: 'iconsminds-financial',
      label: 'My Finances',
      menu: 'MyFinances',
      to: `${adminRoot}/finances`,
    },

    {
      icon: 'iconsminds-embassy',
      label: 'Org',
      menu: 'Org',
      to: `${adminRoot}/orgs`,
    },
    {
      icon: 'iconsminds-notepad',
      label: 'Attendance',
      menu: 'AttendanceMain',
      to: `${adminRoot}/attendances`,
    },
    {
      icon: 'iconsminds-computer',
      label: 'Asset',
      menu: 'AssetMaster',
      to: `${adminRoot}/assets`,
    },
    {
      icon: 'iconsminds-money-bag',
      label: 'Payroll',
      menu: 'Payroll',
      to: `${adminRoot}/payrolls`,
    },
    {
      icon: 'iconsminds-over-time-2',
      label: 'Overtime',
      menu: 'Overtime',
      to: `${adminRoot}/overtimes`,
    },
    {
      icon: 'iconsminds-conference',
      label: 'Pre-Boarding',
      menu: 'PreBoarding',
      to: `${adminRoot}/preboardings`,
    },

    {
      icon: 'iconsminds-remove-file',
      label: 'Off-Boarding',
      menu: 'Offboarding',
      to: `${adminRoot}/offboardings`,
    },

    {
      icon: 'iconsminds-optimization',
      label: 'Report',
      menu: 'Report',
      to: `${adminRoot}/reports`,
    },
    {
      icon: 'iconsminds-the-white-house',
      label: 'Govt. Report',
      menu: 'GovernmentReport',
      to: `${adminRoot}/govtreports`,
    },

    {
      icon: 'iconsminds-id-card',
      label: 'Gate Pass',
      menu: 'GatePass',
      to: `${adminRoot}/gatepasses`,
    },

    {
      icon: 'iconsminds-notepad',
      label: 'Skillsets',
      menu: 'SkillSets',
      to: `${adminRoot}/skillsets`,
    },
    {
      icon: 'iconsminds-support',
      label: 'Help Desk',
      menu: 'HelpDesk',
      to: `${adminRoot}/tickets`,
    },
    {
      icon: 'iconsminds-notepad',
      label: 'CheckList',
      menu: 'CheckList',
      to: `${adminRoot}/checklists`,
    },
    {
      icon: 'iconsminds-embassy',
      label: 'PMS',
      menu: 'PMS',
      to: `${adminRoot}/pms`,
    },
    {
      icon: 'iconsminds-cookies',
      label: 'Utility',
      menu: 'Utility',
      to: `${adminRoot}/utilitys`,
    },
    {
      icon: 'simple-icon-emotsmile',
      label: 'MoodTracker',
      menu: 'MoodTracker',
      to: `${adminRoot}/moodTrackers`,
    },
    {
      icon: 'iconsminds-billing',
      label: 'Income Tax',
      menu: 'IncomeTax',
      to: `${adminRoot}/incometax`,
    },
    {
      icon: 'iconsminds-id-card',
      label: 'Employee GatePass',
      menu: 'EmployeeGatepass',
      to: `${adminRoot}/employeegatepasses`,
    },


  ];
  data1 = data;
}

export default data1;
