<section id="print" *ngIf="chatCountShow">
  <div class="buttonContainer chat-icon" *ngIf="hide">
    <button class="btn btn-primary myButton" (click)="toggleCard()">Mood</button>
    <div class="cardContainer" *ngIf="showCard">
      <div class="card">
        <div class="card-body" style="width: max-content">
          <div *ngIf="!selectedEmoji">
            <p class="text mb-2">How is your mood?</p>
            <div class="emoji-container">
              <span class="emoji" (click)="selectEmoji('happy')" title="Happy">😊</span>
              <span class="emoji" (click)="selectEmoji('notSure')" title="Not Sure">🤔</span>
              <span class="emoji" (click)="selectEmoji('stressed')" title="Stressed">😩</span>
              <span class="emoji" (click)="selectEmoji('sad')" title="Sad">😞</span>
              <span class="emoji" (click)="selectEmoji('angry')" title="Angry">😡</span>
              <span class="emoji" (click)="selectEmoji('ok')" title="OK">👌</span>
            </div>
          </div>
          <div class="success-message" *ngIf="selectedEmoji">
            <p class="text m-0" title="click on Mood">Thank you for your feedback! 😊</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div id="print" class="chat-icon" *ngIf="chatCountShow">
  <a (click)="onChatClick()">
    <div class="position-relative d-inline-flex">
      <span class="badge badge-primary-custom rounded-circle position-absolute top-0 end-0 translate-middle p-1"
        style="font-size: 12px; right: 0px; bottom: 30px">
        {{ chatCount }}
      </span>
      <span class="glyph-icon iconsminds-speach-bubble tool_tip" style="font-size: 32px; color: white"
        tooltip="chat"></span>
    </div>
    {{ ' ' }}
  </a>
</div>

<simple-notifications [options]="{ position: ['top', 'center'], maxStack: 1 }"></simple-notifications>