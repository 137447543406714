import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ConstantService } from '../services/constant.service';
import { ChatService } from '../services/chat.service';
import { ChatNotificationCountService } from '../services/chat-notification-count.service';
import { NotificationType, NotificationsService } from 'angular2-notifications';
@Component({
  selector: 'app-chat-notification',
  templateUrl: './chat-notification.component.html',
  styleUrls: ['./chat-notification.component.scss'],
})
export class ChatNotificationComponent implements OnInit {
  chatCount: any;
  chatCountShow: boolean = false;
  showCard: boolean = false;
  selectedEmoji: string | null = null;
  hide: boolean = false;

  constructor(
    private route: Router,
    private router: Router,
    private api: ApiService,
    private constant: ConstantService,
    private chatService: ChatService,
    private notifications: NotificationsService,
    private chatNotificationCountService: ChatNotificationCountService,
  ) { }

  ngOnInit(): void {
    this.chatNotificationCountService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.chatCountShow = true;
        this.chatService.onMessage().subscribe((message: any) => {
          if (message) {
            this.getChatNotificationCount();
          }
        });
        this.chatNotificationCountService.refreshComponent$.subscribe(() => {
          this.getChatNotificationCount();
        });

        this.showCard = false;
        this.selectedEmoji = null;
        this.hide = false;

        this.getChatNotificationCount();
        this.getFeedbackData();
      } else {
        this.chatCountShow = false;
      }
    });
  }

  onChatClick() {
    this.router.navigate(['app/tickets/chat']);
  }

  getChatNotificationCount() {
    this.api
      .callApi(
        this.constant.GETUSERLIST,
        { page: '', limit: '', receiverID: localStorage.getItem('id') },
        'POST',
        false,
        false,
        true,
      )
      .subscribe((res: any) => {
        if (res.status == 200) {
          let chatData = res.data;
          this.chatCount = chatData.reduce((sum, chat) => sum + chat.messageCount, 0);
        }
      });
  }

  toggleCard() {
    if (this.selectedEmoji) {
      this.hide = false;
    }
    this.showCard = true;
  }

  getFeedbackData() {
    let id = +localStorage.getItem('id');
    let date = new Date().toISOString().slice(0, 10);
    if (!id) {
      return;
    }

    let query = `?page=1&pageSize=10&userMasterId=${id}&startDate=${date}&endDate=${date}`;
    this.api
      .callApi(this.constant.SENTIMENTPUNCHINAPI + query, {}, 'GET', false, false, true)
      .subscribe(
        (res: any) => {
          if (res.data.length > 0) {
            this.hide = false;
          } else {
            this.hide = true;
          }
        },
        (err) => {
          this.notifications.create('Opps!', 'Something Went Wrong!', NotificationType.Error, {
            theClass: 'outline primary',
            timeOut: 3000,
            showProgressBar: false,
          });
        },
      );
  }

  selectEmoji(emoji: string) {
    if (!emoji) {
      return;
    }
    this.selectedEmoji = emoji;

    this.api
      .callApi(this.constant.SENTIMENTPUNCHINAPI, { mood: emoji }, 'POST', false, false, true)
      .subscribe(
        (res: any) => {
          setTimeout(() => {
            this.hide = false;
          }, 1000);
        },
        (err) => {
          this.notifications.create('Opps!!', err.error.message, NotificationType.Error, {
            theClass: 'outline primary',
            timeOut: 3000,
            showProgressBar: false,
          });
        },
      );
  }
}
