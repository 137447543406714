import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';
import { ConstantService } from 'src/app/services/constant.service';
import { environment } from 'src/environments/environment';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-user-request-box',
  templateUrl: './user-request-box.component.html',
  styleUrls: ['./user-request-box.component.scss'],
})
export class UserRequestBoxComponent implements OnInit {
  @ViewChild('scroll')
  scrollRef: PerfectScrollbarComponent;
  adminRoot = environment.adminRoot;
  messageResData: any = [];
  messageTotalCount: any;
  permissionview: any = [];

  constructor(
    private router: Router,
    private api: ApiService,
    private constant: ConstantService,
    private notifications: NotificationsService,
    private modalService: ModalService

  ) { }

  ngOnInit(): void {
    this.modalService.userRequestRefresh$.subscribe(() => {
      this.getData();
    });
    // this.checkpermission();

    this.getData();
  }

  // checkpermission() {
  //   let body = {
  //     id: localStorage.getItem('id'),
  //   };
  //   this.api
  //     .callApi(this.constant.GETPERMISSION, body, 'POST', true, false, true)
  //     .subscribe((res: any) => {
  //       if (res.status == 200) {
  //         let permission = res.data;
  //         this.permissionview = permission.filter((permissionval) => {
  //           return (
  //             permissionval.formName == 'RequestBox' &&
  //             permissionval.operationName.includes('View')
  //           );
  //         });
  //       }
  //     });
  // }

  getData() {
    this.api
      .callApi(this.constant.GETUSERINBOXDATA + `?page=1&limit=25`, {}, 'GET', false, false, true)
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.messageResData = [];
            this.messageTotalCount = res.totalcount;
            res.data.forEach((element) => {
              this.messageResData.push(element);
            });
          } else {
            this.handleCatchError();
          }
        },
        (err) => {
          this.handleCatchError();
        },
      );
  }

  redirect() {
    this.router.navigate([this.adminRoot + '/reuqestInbox']);
  }

  handleCatchError() {
    this.notifications.create('Error', 'Something Went Wrong!', NotificationType.Error, {
      theClass: 'outline primary',
      timeOut: 2000,
      showProgressBar: false,
    });
  }

  setType(tab: string) {
    const typeMapping = {
      gatePasses: 'Gatepass',
      advancePayments: 'Advance',
      leaveAuthorizations: 'Leave',
      userExpenses: 'Expense',
      loanMasters: 'Loan',
      ticketCategories: 'Ticket',
    };

    return typeMapping[tab] || '';
  }

  setIcon(tab: string) {
    const iconMappings = {
      gatePasses: 'iconsminds-id-card',
      advancePayments: 'iconsminds-increase-inedit',
      leaveAuthorizations: 'iconsminds-calendar-4',
      userExpenses: 'iconsminds-money-bag',
      loanMasters: 'iconsminds-bank',
      ticketCategories: 'iconsminds-support',
    };

    return iconMappings[tab] || '';
  }
}
